/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ReachabilityPoint, SatisfactionOption} from 'fragments/Map';
import {defaultSatisfactionOptions} from '../config/satisfactionOptions';
import {MapPoint} from './mapSlice';

export interface SettingsState {
  selectedSatisfactionOption: SatisfactionOption | null,
  satisfactionOptions: SatisfactionOption[],
  reachabilityPoints: ReachabilityPoint[],
  favouritePlaces: FavouritePlace[],
  favouriteMatches: FavouriteMatch[],
  searchResultPlaces: SearchResultPlace[],
  changedReachability: boolean,
}

const initialState: SettingsState = {
  selectedSatisfactionOption: null,
  satisfactionOptions: [],
  reachabilityPoints: [],
  favouritePlaces: [],
  favouriteMatches: [],
  searchResultPlaces: [],
  changedReachability: true,
};

export interface FavouritePlace extends MapPoint {
  name: string
}

export interface FavouriteMatch extends FavouritePlace {
  id: string,
}

export interface SearchResultPlace extends MapPoint {
  name: string
}

export interface ChangeReachabilityPointTravelType {
  id: number,
  travelType: string,
}

export interface ChangeReachabilityPointMaxTravelTime {
  id: number,
  maxTravelTime: number,
}

export interface ChangeSatisfactionOptionThresholdAmount {
  id: string,
  thresholdForMaxSatisfaction: number,
  satisfactionDistance: number,
}

export interface MapShareData {
  reachabilityPoints: ReachabilityPoint[],
  satisfactionOptions: SatisfactionOption[]
}

export interface SetSelectedSatisfactionOption {
  satisfactionOption: SatisfactionOption | null,
}

export interface SetDefaultSatisfactionOptions {
  satisfactionOptions: SatisfactionOption[],
}

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    addReachabilityPoint: (state, action: PayloadAction<ReachabilityPoint>) => {
      state.reachabilityPoints.push(action.payload);
      state.changedReachability = true;
    },
    removeReachabilityPoint: (state, action: PayloadAction<number>) => {
      state.reachabilityPoints = state.reachabilityPoints.filter((reachabilityPoint) => reachabilityPoint.id !== action.payload);
    },
    clearReachabilityPoints: (state) => {
      state.reachabilityPoints = [];
    },
    addFavouritePlace: (state, action: PayloadAction<FavouritePlace>) => {
      state.favouritePlaces = state.favouritePlaces.filter((favouritePlace) => favouritePlace.lat !== action.payload.lat || favouritePlace.lon !== action.payload.lon || favouritePlace.name !== action.payload.name);
      state.favouritePlaces.push(action.payload);
    },
    addFavouriteMatch: (state, action: PayloadAction<FavouriteMatch>) => {
      state.favouriteMatches = state.favouriteMatches.filter((favouriteMatch) => favouriteMatch.id !== action.payload.id);
      state.favouriteMatches.push(action.payload);
    },
    removeFavouritePlace: (state, action: PayloadAction<FavouritePlace>) => {
      state.favouritePlaces = state.favouritePlaces.filter((favouritePlace) => JSON.stringify(favouritePlace) !== JSON.stringify(action.payload));
    },
    removeFavouriteMatch: (state, action: PayloadAction<string>) => {
      state.favouriteMatches = state.favouriteMatches.filter((favouriteMatch) => favouriteMatch.id !== action.payload);
    },
    addSearchResultPlace: (state, action: PayloadAction<SearchResultPlace>) => {
      state.searchResultPlaces = state.searchResultPlaces.filter((searchResult) => searchResult.lat !== action.payload.lat || searchResult.lon !== action.payload.lon || searchResult.name !== action.payload.name);
      state.searchResultPlaces.unshift(action.payload);
      if (state.searchResultPlaces.length > 15) {
        state.searchResultPlaces = state.searchResultPlaces.slice(0, 15);
      }
    },
    removeSearchResultPlace: (state, action: PayloadAction<SearchResultPlace>) => {
      state.searchResultPlaces = state.searchResultPlaces.filter((searchResult) => JSON.stringify(searchResult) !== JSON.stringify(action.payload));
    },
    changeReachabilityPointTravelType: (state, action: PayloadAction<ChangeReachabilityPointTravelType>) => {
      const reachabilityPoint = state.reachabilityPoints.find((rP) => rP.id === action.payload.id);
      if (reachabilityPoint) {
        reachabilityPoint.travelType = action.payload.travelType;
      } else {
        console.error('Reachability point not found');
      }
      state.changedReachability = true;
    },
    changeReachabilityPointMaxTravelTime: (state, action: PayloadAction<ChangeReachabilityPointMaxTravelTime>) => {
      const reachabilityPoint = state.reachabilityPoints.find((rP) => rP.id === action.payload.id);
      if (reachabilityPoint) {
        reachabilityPoint.maxTravelTime = action.payload.maxTravelTime;
      } else {
        console.error('Reachability point not found');
      }
      state.changedReachability = true;
    },
    changeSatisfactionOption: (state, action: PayloadAction<ChangeSatisfactionOptionThresholdAmount>) => {
      const {id, thresholdForMaxSatisfaction, satisfactionDistance} = action.payload;
      const satisfactionOption = state.satisfactionOptions.find((so) => so.id === id);
      if (satisfactionOption) {
        satisfactionOption.thresholdForMaxSatisfaction = thresholdForMaxSatisfaction;
        satisfactionOption.satisfactionDistance = satisfactionDistance;
      } else {
        console.error('Satisfaction option point not found');
      }
    },
    applyMapShareData: (state, action: PayloadAction<MapShareData>) => {
      state.reachabilityPoints = action.payload.reachabilityPoints;
      state.satisfactionOptions = action.payload.satisfactionOptions;
    },
    setSelectedSatisfactionOption: (state, action: PayloadAction<SetSelectedSatisfactionOption>) => {
      state.selectedSatisfactionOption = action.payload.satisfactionOption;
    },
    setDefaultSatisfactionOption: (state, action: PayloadAction<SetDefaultSatisfactionOptions>) => {
      state.satisfactionOptions = action.payload.satisfactionOptions;
    },
    setProcessedChangedReachability: (state) => {
      state.changedReachability = false;
    },
  },
});

export const {
  addReachabilityPoint,
  removeReachabilityPoint,
  changeReachabilityPointTravelType,
  changeReachabilityPointMaxTravelTime,
  changeSatisfactionOption,
  addFavouritePlace,
  removeFavouritePlace,
  addFavouriteMatch,
  removeFavouriteMatch,
  addSearchResultPlace,
  removeSearchResultPlace,
  applyMapShareData,
  setSelectedSatisfactionOption,
  setDefaultSatisfactionOption,
  setProcessedChangedReachability,
  clearReachabilityPoints,
} = settingsSlice.actions;

export default settingsSlice.reducer;
