/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MapPoint} from './mapSlice';
import {Place, ReachabilityPoint} from '../fragments/Map';

export enum LoadingHeatmapState {
  Error = -1,
  NotStarted = 0,
  MetaDataRequesting = 1,
  HeatmapRequesting = 2,
  ImageRequesting = 3,
  Finished = 4,
}

export enum Dialogs {
  Welcome, Satisfaction, Reachability,
}

export interface UiState {
  sidebarOpen: boolean,
  markFirstPageOpen: boolean,
  openDialog: Dialogs | null,
  loadingHeatmapState: LoadingHeatmapState,
  mapFullscreen: boolean,
  lastAddedCoordinate: MapPoint | null,
  invalidRequestedData: boolean,
  lastClickedPlaceMenuItem: Place | null,
  displayMatchesNumber: number | null,
  replacementReachability: ReachabilityPoint | null,
}

const initialState: UiState = {
  sidebarOpen: false,
  markFirstPageOpen: false,
  openDialog: null,
  loadingHeatmapState: LoadingHeatmapState.NotStarted,
  mapFullscreen: false,
  lastAddedCoordinate: null,
  invalidRequestedData: false,
  lastClickedPlaceMenuItem: null,
  displayMatchesNumber: null,
  replacementReachability: null,
};

interface Address {
  streetAndNumber: string,
  zip: string,
  city: string,
}

export interface Match extends Place {
  id:string,
  score:number,
}

interface MatchAttribute {
  key: string,
  value: string,
}

export interface MatchDetails extends Place {
  id:string,
  images: string[],
  description: string,
  address: Address | null,
  score:number,
  attributes: MatchAttribute[],
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    openSidebar: (state) => {
      state.sidebarOpen = true;
    },
    closeSidebar: (state) => {
      state.sidebarOpen = false;
    },
    markFirstPageOpen: (state) => {
      state.markFirstPageOpen = true;
    },
    openWelcomeDialog: (state) => {
      state.openDialog = Dialogs.Welcome;
    },
    closeDialog: (state) => {
      state.openDialog = null;
      state.mapFullscreen = true;
    },
    openSatisfactionDialog: (state) => {
      state.openDialog = Dialogs.Satisfaction;
    },
    openReachabilityDialog: (state) => {
      state.openDialog = Dialogs.Reachability;
    },
    openReplacementReachabilityDialog: (state, action: PayloadAction<ReachabilityPoint>) => {
      state.replacementReachability = action.payload;
    },
    closeReplacementReachabilityDialog: (state) => {
      state.replacementReachability = null;
      state.mapFullscreen = true;
    },
    setLoadingHeatmapState: (state, action: PayloadAction<LoadingHeatmapState>) => {
      state.loadingHeatmapState = action.payload;
    },
    setMapFullscreen: (state, action: PayloadAction<boolean>) => {
      state.mapFullscreen = action.payload;
    },
    setLastAddedCoordinate: (state, action: PayloadAction<MapPoint>) => {
      state.lastAddedCoordinate = action.payload;
    },
    setLastClickedPlaceMenuItem: (state, action: PayloadAction<Place | null>) => {
      state.lastClickedPlaceMenuItem = action.payload;
    },
    setInvalidRequestedData: (state, action: PayloadAction<boolean>) => {
      state.invalidRequestedData = action.payload;
      if (action.payload) {
        state.openDialog = Dialogs.Reachability;
      }
    },
    setDisplayMatchesNumber: (state, action: PayloadAction<number>) => {
      state.displayMatchesNumber = action.payload;
    },
  },
});

export const {
  openSidebar, closeSidebar,
  openSatisfactionDialog, openReachabilityDialog, closeDialog,
  setLoadingHeatmapState, openWelcomeDialog, markFirstPageOpen,
  setMapFullscreen, setLastAddedCoordinate, setInvalidRequestedData,
  setLastClickedPlaceMenuItem, setDisplayMatchesNumber,
  openReplacementReachabilityDialog, closeReplacementReachabilityDialog,
} = uiSlice.actions;

export default uiSlice.reducer;
