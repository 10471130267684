import React, {useEffect, useMemo, useState} from 'react';
import {
  AccordionDetails, Box, Button, Divider, Grid, useMediaQuery,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import List from '@mui/material/List';
import {get} from 'lodash';
import L, {LatLng, Map as LeafletMap} from 'leaflet';
import {RootState} from 'store/store';
import {addSearchResultPlace, removeSearchResultPlace} from 'store/settingsSlice';
import {useMap} from 'react-leaflet';
import SearchIcon from '@mui/icons-material/Search';
import PlaceMenuItem from './PlaceMenuItem';
import AutoCompleteLocation, {PlaceType} from '../components/AutoCompleteLocation';
import {SearchResultPlace} from '../store/settingsSlice';
import {usePlacesMarker} from '../hooks/map/placesMarker';
import {MarkerColor} from '../utils/markerUtils';
import ChipAccordion from '../components/ChipAccordion';
import {HeatmapMetaDataResponse} from '../hooks/map/heatmap';
import {
  openReplacementReachabilityDialog, setLastClickedPlaceMenuItem,
} from '../store/uiSlice';
import {ReachabilityPoint} from './Map';
import theme from '../style/theme';

const SearchAccordion = () => {
  const dispatch = useDispatch();
  const searchResultPlaces:SearchResultPlace[] = useSelector((state: RootState) => get(state, 'settings.searchResultPlaces', []));
  const heatmapMetaDataResponse:HeatmapMetaDataResponse = useSelector((state: RootState) => get(state, 'map.heatmapMetaData', []));
  const [autoCompleteValue, setAutoCompleteValue] = React.useState<PlaceType | null >(null);
  const [loadingAutocomplete, setLoadingAutocomplete] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);
  const map:LeafletMap = useMap();

  useEffect(() => {
    if (isMobile) {
      setExpandedAccordion(true);
    }
  }, [isMobile]);

  const onClickAddAutoComplete = async () => {
    if (autoCompleteValue == null) {
      return;
    }
    const dataUrl:string = `/api/places/details?place_id=${autoCompleteValue.placeId}`;
    setLoadingAutocomplete(true);
    const response = await fetch(dataUrl);
    setLoadingAutocomplete(false);

    const data:any = JSON.parse(await response.text());
    setAutoCompleteValue(null);

    if (heatmapMetaDataResponse.boundingBox && !L.latLngBounds(heatmapMetaDataResponse.boundingBox).contains([data.lat, data.lng])) {
      dispatch(openReplacementReachabilityDialog({
        name: autoCompleteValue.structuredFormatting.mainText,
        lat: data.lat,
        lon: data.lng,
      } as ReachabilityPoint));
      return;
    }

    dispatch(addSearchResultPlace({name: autoCompleteValue.structuredFormatting.mainText, lat: data.lat, lon: data.lng}));
    map.flyTo(new LatLng(data.lat, data.lng), 15, {animate: true});
    dispatch(setLastClickedPlaceMenuItem({
      name: autoCompleteValue.structuredFormatting.mainText, lat: data.lat, lon: data.lng, type: '',
    }));
  };

  const onClickRemoveSearchEntry = (place:SearchResultPlace) => {
    dispatch(removeSearchResultPlace(place));
  };

  const markerData = useMemo(() => (expandedAccordion ? searchResultPlaces?.map((marker) => ({
    lat: marker.lat, lon: marker.lon, popupHtml: `<b>${marker.name}</b>`,
  })) : []), [searchResultPlaces, expandedAccordion]);

  usePlacesMarker(map, markerData, MarkerColor.Yellow);

  const renderSearchHistory = () => {
    if (!searchResultPlaces.length) {
      return <></>;
    }

    return (
      <>
        <PlaceMenuItem
          name={searchResultPlaces[0].name}
          lat={searchResultPlaces[0].lat}
          lon={searchResultPlaces[0].lon}
          onClickRemove={() => onClickRemoveSearchEntry(searchResultPlaces[0])}
        />
        {searchResultPlaces.length > 1 && <Divider />}
        {searchResultPlaces.slice(1).map((searchResult) => (
          <PlaceMenuItem
            name={searchResult.name}
            lat={searchResult.lat}
            lon={searchResult.lon}
            key={searchResult.name + searchResult.lat + searchResult.lon}
            onClickRemove={() => onClickRemoveSearchEntry(searchResult)}
          />
        ))}
      </>
    );
  };

  return (
    <>
      <ChipAccordion
        onChange={(event, isExpanded) => setExpandedAccordion(isExpanded)}
        expandedAccordion={expandedAccordion}
        title="Search"
        Icon={<SearchIcon />}
      >
        <AccordionDetails>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={8}>
              <AutoCompleteLocation setValue={setAutoCompleteValue} value={autoCompleteValue} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box display="flex" justifyContent="flex-end" alignItems="end">
                <Button
                  size="large"
                  fullWidth
                  variant="outlined"
                  onClick={onClickAddAutoComplete}
                  disabled={loadingAutocomplete}
                >
                  Search
                </Button>
              </Box>
            </Grid>
          </Grid>
          <List component="nav" style={{width: '100%'}}>
            {renderSearchHistory()}
          </List>
        </AccordionDetails>
      </ChipAccordion>
    </>
  );
};

export default SearchAccordion;
