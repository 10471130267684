import React from 'react';
import {Box, Typography} from '@mui/material';
import {colors} from '../style/colors';

export const explanations = [
  (
    <Box p={0}>
      {'The degree of agreement is indicated by colors from '}
      <Typography sx={{backgroundColor: colors.heatmap.bad, paddingX: '0.2rem'}} component="span">weak</Typography>
      {' to '}
      <Typography sx={{backgroundColor: colors.heatmap.good, paddingX: '0.2rem'}} component="span">very strong</Typography>
      {' Areas beyond reachability are indicated by '}
      <Typography sx={{backgroundColor: colors.heatmap.outside, paddingX: '0.2rem'}} component="span">dark grey</Typography>
      .
    </Box>
  ),
];
