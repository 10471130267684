import React, {useEffect, useState} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import {MatchDetails} from 'store/uiSlice';
import Carousel from 'react-material-ui-carousel';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {useNavigate, useParams} from 'react-router-dom';
import {LatLng, Map as LeafletMap} from 'leaflet';
import {Marker, useMap} from 'react-leaflet';
import {addFavouriteMatch, FavouriteMatch, removeFavouriteMatch} from '../store/settingsSlice';
import Toolbar from '../fragments/Toolbar';
import {RootState} from '../store/store';
import AddressLabel from '../fragments/AddressLabel';
import ReachabilityList from '../fragments/ReachabilityList';
import NearPlacesGroupsAccordion from '../fragments/NearPlacesGroupsAccordion';
import {useHeatmapColorPicker} from '../hooks/heatmapColorPicker';
import {routes} from '../config/routeConfig';
import {LoadingIndicator} from '../components/LoadingIndicator';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../utils/markerUtils';

const useStyles = makeStyles(() => ({
  carouselImageItem: {
    width: '100%',
    minHeight: '250px',
    objectFit: 'cover',
    borderRadius: '5px',
  },
  carousel: {
    width: '100%',
  },
  attributeKeyLabel: {
    fontWeight: 600,
  },
}));

const MatchPage = () => {
  const classes = useStyles();
  const [matchDetails, setMatchDetails] = useState<MatchDetails | null>(null);
  const dispatch = useDispatch();
  const favouriteMatches:FavouriteMatch[] = useSelector((state: RootState) => get(state, 'settings.favouriteMatches', []));
  const heatmapColorPicker = useHeatmapColorPicker();
  const params = useParams();
  const navigate = useNavigate();
  const map:LeafletMap = useMap();

  const loadMatch = async () => {
    const requestUrl:string = `/api/matches/${params.id}`;
    const response:Response = await fetch(requestUrl);
    const matchResponse:MatchDetails = await JSON.parse(await response.text());

    setMatchDetails({
      ...matchResponse,
      // Todo: Placeholder due lacking real match
      // ...{
      //   summary: 'Ein tolles Angebot direkt ab nächstem Monat zu mieten. ',
      //   description: 'Diese einzigartige Wohnung ist in einem Mehrfamilienhaus im 3. Stock und bietet durch die perfekte Aufteilung Platz für ..  ',
      //   address: {
      //     streetAndNumber: 'Hauptweg 123',
      //     zip: '10627',
      //     city: 'Berlin',
      //   },
      //   attributes: [
      //     {key: 'Warmmiete', value: '620€'},
      //     {key: 'Kaltmiete', value: '450€'},
      //     {key: 'Vermietung', value: 'Private'}],
      //   images: [
      //     '/examplePicture1.jpg',
      //     '/examplePicture2.jpg',
      //     '/examplePicture3.jpg',
      //   ],
      // },
    });
  };

  useEffect(() => {
    if (!matchDetails || matchDetails.id !== params.id) {
      loadMatch();
    } else {
      map.flyTo(new LatLng(matchDetails.lat, matchDetails.lon), map.getZoom() < 15 ? 15 : undefined);
    }
  }, [matchDetails, params.id]);

  if (!matchDetails) {
    return (<LoadingIndicator />);
  }

  const renderImageCarousel = () => (
    <Carousel
      duration={300}
      interval={8000}
      className={classes.carousel}
      stopAutoPlayOnHover
      animation="slide"
    >
      {
        matchDetails.images.map((image) => (
          <img className={classes.carouselImageItem} alt="Match" key={image} src={image} />
        ))
        }
    </Carousel>
  );

  const handleClose = () => {
    navigate(routes.Startpage.path);
  };

  const alreadyInFavourites = favouriteMatches.some((favouriteMatch:FavouriteMatch) => favouriteMatch.lat === matchDetails.lat && favouriteMatch.lon === matchDetails.lon);

  const handleSaveFavourite = () => {
    dispatch(addFavouriteMatch({
      lat: matchDetails.lat, lon: matchDetails.lon, name: matchDetails.name, id: matchDetails.id,
    }));
  };

  const handleRemoveFavourite = () => {
    dispatch(removeFavouriteMatch(matchDetails.id));
  };

  const markerIcon = createMarkerIcon(MarkerColor.Red, MarkerSize.Big);

  return (
    <>
      <Marker position={[matchDetails.lat, matchDetails.lon]} icon={markerIcon} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Toolbar mapPoint={matchDetails} alreadyInFavourites={alreadyInFavourites} onClose={handleClose} onSaveFavourite={handleSaveFavourite} onRemoveFavourite={handleRemoveFavourite} />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" width="100%">
            <Typography variant="h5">
              {matchDetails.name}
            </Typography>
          </Box>
        </Grid>
        {matchDetails.description && (
          <Grid item xs={12}>
            <Box justifyContent="center">
              <Typography variant="subtitle1">
                &quot;
                {matchDetails.description}
                &quot;
              </Typography>
            </Box>
          </Grid>
        )}
        {matchDetails.attributes && matchDetails.attributes.length > 0 && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {matchDetails.attributes.map((attribute) => (
                <Grid item xs={4} key={attribute.key}>
                  <Grid container direction="column">
                    <Grid item xs={12}>
                      <Typography variant="caption" className={classes.attributeKeyLabel}>
                        {`${attribute.key}: `}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        {attribute.value}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        )}
        {matchDetails.images && matchDetails.images.length > 0 && (
          <Grid item xs={12}>
            { renderImageCarousel()}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{paddingLeft: '5px', borderLeft: `4px solid ${heatmapColorPicker.getColorAtCoordinate(matchDetails.lat, matchDetails.lon)}`}}>
            <AddressLabel mapPoint={matchDetails} variant="medium" />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <ReachabilityList lat={matchDetails.lat} lon={matchDetails.lon} useAccordion={false} />
        </Grid>
        <Grid item xs={12}>
          <NearPlacesGroupsAccordion mapPoint={matchDetails} />
        </Grid>
      </Grid>
    </>
  );
};

export default MatchPage;
