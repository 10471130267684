import React, {useEffect, useState} from 'react';
import {
  AccordionDetails, AccordionSummary, Grid, Typography, useMediaQuery,
} from '@mui/material';
import {useSelector} from 'react-redux';
import List from '@mui/material/List';
import {get} from 'lodash';
import {RootState} from 'store/store';
import FavouritesIcon from '@mui/icons-material/Favorite';
import {generatePath, useNavigate} from 'react-router-dom';
import {FavouriteMatch, FavouritePlace} from '../store/settingsSlice';
import PlaceMenuItem from './PlaceMenuItem';
import {routes} from '../config/routeConfig';
import ChipAccordion from '../components/ChipAccordion';
import theme from '../style/theme';

const FavouriteAccordion = () => {
  const favouritePlaces:FavouritePlace[] = useSelector((state: RootState) => get(state, 'settings.favouritePlaces', []));
  const favouriteMatches:FavouriteMatch[] = useSelector((state: RootState) => get(state, 'settings.favouriteMatches', []));
  const navigate = useNavigate();
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const totalFavourites = favouriteMatches.length + favouritePlaces.length;

  useEffect(() => {
    if (isMobile) {
      setExpandedAccordion(true);
    }
  }, [isMobile]);

  if (totalFavourites === 0) {
    return <></>;
  }

  const openMatchInDetail = (matchId:string) => {
    navigate(generatePath(routes.MatchDetails.path, {id: matchId}));
  };

  return (
    <Grid item xs={12}>
      <ChipAccordion
        onChange={(event, isExpanded) => setExpandedAccordion(isExpanded)}
        expandedAccordion={expandedAccordion}
        title={`Saved places (${totalFavourites})`}
        Icon={<FavouritesIcon />}
      >
        <AccordionDetails>
          {favouriteMatches.length > 0 && (
            <List component="nav" style={{width: '100%'}}>
              {favouriteMatches.map((favouriteMatch) => (
                <PlaceMenuItem
                  name={favouriteMatch.name}
                  lat={favouriteMatch.lat}
                  lon={favouriteMatch.lon}
                  key={favouriteMatch.name + favouriteMatch.lat + favouriteMatch.lon}
                  onDetail={() => openMatchInDetail(favouriteMatch.id)}
                />
              ))}
            </List>
          )}
          {favouritePlaces.length > 0 && (
            <List component="nav" style={{width: '100%'}}>
              {favouritePlaces.map((favouritePlace) => (
                <PlaceMenuItem name={favouritePlace.name} lat={favouritePlace.lat} lon={favouritePlace.lon} key={favouritePlace.name + favouritePlace.lat + favouritePlace.lon} />
              ))}
            </List>
          )}
        </AccordionDetails>
      </ChipAccordion>
    </Grid>
  );
};

export default FavouriteAccordion;
