import React from 'react';
import {
  Box, Button,
  Grid, ListItem, ListItemIcon, ListItemText, useMediaQuery, useTheme,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {openReachabilityDialog, openSatisfactionDialog} from 'store/uiSlice';
import LocalActivityIcon from '@mui/icons-material/GpsFixedTwoTone';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {generatePath, useNavigate} from 'react-router-dom';
import ChipPanel, {ChipBackgroundSx} from '../../../components/ChipPanel';
import Logo from '../../../components/Logo';
import {RootState} from '../../../store/store';
import {routes} from '../../../config/routeConfig';

function AroundMeButton() {
  const navigate = useNavigate();

  return (
    <Button
      startIcon={<LocalActivityIcon />}
      onClick={() => {
        navigate(generatePath(routes.AroundMe.path));
      }}
      color="inherit"
      sx={{...ChipBackgroundSx, marginRight: '4px !important'}}
    >
      My place
    </Button>
  );
}

export default AroundMeButton;
