import React, {useMemo} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography,
} from '@mui/material';
import {get} from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSelector} from 'react-redux';
import {ReachabilityPoint} from './Map';
import {RootState} from '../store/store';
import ReachabilityListEntry from './ReachabilityListEntry';
import ChipAccordion from '../components/ChipAccordion';

interface ReachabilityListProps {
  lat:number,
  lon:number,
  useAccordion:boolean
}

const ReachabilityList = ({
  lat, lon, useAccordion,
}:ReachabilityListProps) => {
  const storedReachabilityPoints:ReachabilityPoint[] = useSelector((state: RootState) => get(state, 'settings.reachabilityPoints', []));
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);

  const reachabilityPoints:ReachabilityPoint[] | null = useMemo(() => (isExpanded ? storedReachabilityPoints.map((storedReachabilityPoint) => ({...storedReachabilityPoint})) : null),
    [storedReachabilityPoints, isExpanded]);

  if (!useAccordion) {
    return (
      <>
        {storedReachabilityPoints?.map((reachabilityPoint) => (
          <Grid item xs={12} key={reachabilityPoint.id}>
            <Box px={1}>
              <ReachabilityListEntry reachabilityPoint={reachabilityPoint} lat={lat} lon={lon} />
            </Box>
          </Grid>
        ))}
      </>
    );
  }

  return (
    <ChipAccordion expandedAccordion={isExpanded} onChange={(event, newIsExpanded) => setIsExpanded(newIsExpanded)} title="Reachability">
      <AccordionDetails sx={{paddingRight: 1}}>
        <Grid container>
          {isExpanded && reachabilityPoints?.map((reachabilityPoint) => (
            <Grid item xs={12} key={reachabilityPoint.id}>
              <ReachabilityListEntry reachabilityPoint={reachabilityPoint} lat={lat} lon={lon} />
            </Grid>
          ))}
        </Grid>
      </AccordionDetails>
    </ChipAccordion>
  );
};

export default ReachabilityList;
