import React, {useEffect} from 'react';
import {Grid, Typography} from '@mui/material';
import {useNavigate, useParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {useSnackbar} from 'notistack';
import {LoadingIndicator} from '../components/LoadingIndicator';
import {ReachabilityPoint, SatisfactionOption} from '../fragments/Map';
import {applyMapShareData, MapShareData} from '../store/settingsSlice';
import {defaultSatisfactionOptions} from '../config/satisfactionOptions';
import {markFirstPageOpen} from '../store/uiSlice';
import {routes} from '../config/routeConfig';

interface MapShareResponse {
  content: string,
  version: number
}

interface MapShareContent {
  reachabilityPoints: ReachabilityPoint[],
  selectedSatisfactionOptions: SatisfactionOption[]
}

const MapSharePage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const {enqueueSnackbar} = useSnackbar();
  const navigate = useNavigate();

  const mergeSatisfactionOptions = (selectedSatisfactionOptions: SatisfactionOption[]): SatisfactionOption[] => defaultSatisfactionOptions.map((defaultSatisfactionOption) => {
    const found = selectedSatisfactionOptions.find((selectedSatisfactionOption) => selectedSatisfactionOption.id === defaultSatisfactionOption.id);
    if (found) {
      return {...defaultSatisfactionOption, ...found};
    }
    return defaultSatisfactionOption;
  });

  const loadSharingData = async () => {
    const requestUrl: string = `/api/map-share/${params.id}`;
    const response: Response = await fetch(requestUrl);
    const mapShareResponse: MapShareResponse = JSON.parse(await response.text());
    const parsedContent: MapShareContent = JSON.parse(mapShareResponse.content);

    if (mapShareResponse.version > 0) {
      const satisfactionOptions = mergeSatisfactionOptions(parsedContent.selectedSatisfactionOptions);
      const payload: MapShareData = {reachabilityPoints: parsedContent.reachabilityPoints, satisfactionOptions};
      await dispatch(applyMapShareData(payload));
      await dispatch(markFirstPageOpen());
      enqueueSnackbar('Geteilte Karte geladen');
    } else {
      enqueueSnackbar('Fehler beim Laden');
    }
    navigate(routes.Startpage.path);
  };

  useEffect(() => {
    loadSharingData();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <LoadingIndicator />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">
          Geteilte Daten laden..
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MapSharePage;
