import {Box, CircularProgress} from '@mui/material';
import React from 'react';

export function LoadingIndicator() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      width="100%"
      py={4}
    >
      <CircularProgress size={75} />
    </Box>
  );
}
