import React, {ReactChild, ReactChildren} from 'react';
import {Accordion, AccordionSummary, Typography} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {SvgIconComponent} from '@mui/icons-material';
import {ChipBackgroundSx} from './ChipPanel';

interface RoundAccordionProps {
  onChange?: (event: React.SyntheticEvent, expanded: boolean) => void,
  expandedAccordion?: boolean,
  children: ReactChild,
  Icon?: ReactChild,
  title: string,
}

function ChipAccordion({
  children, onChange, expandedAccordion, title, Icon,
}:RoundAccordionProps) {
  return (
    <Accordion
      onChange={onChange}
      expanded={expandedAccordion}
      sx={{
        ...ChipBackgroundSx,
        backgroundColor: 'white',
        borderRadius: {xs: expandedAccordion ? '4px !important' : '7px !important', sm: undefined},
        paddingX: expandedAccordion ? '2px' : '5px',
        width: {xs: expandedAccordion ? '100%' : '200px'},
      }}
      elevation={expandedAccordion ? 0 : 1}
    >
      <AccordionSummary
        // sx={{marginY: '0px'}}
        expandIcon={expandedAccordion ? <ExpandMoreIcon /> : (Icon || <ExpandMoreIcon />)}
      >
        <Typography variant={expandedAccordion ? 'h6' : 'subtitle2'} fontWeight="bold">
          {title}
        </Typography>
      </AccordionSummary>
      {children}
    </Accordion>
  );
}

export default ChipAccordion;
