import React, {useEffect, useMemo, useState} from 'react';
import {Box, Grid, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {Marker, useMap} from 'react-leaflet';
import L, {LatLng, LatLngBoundsLiteral, Map as LeafletMap} from 'leaflet';
import {get} from 'lodash';
import {addFavouritePlace, FavouritePlace, removeFavouritePlace} from 'store/settingsSlice';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {RootState} from '../store/store';
import {MapPoint} from '../store/mapSlice';
import ReachabilityList from '../fragments/ReachabilityList';
import {useHeatmapColorPicker} from '../hooks/heatmapColorPicker';
import Toolbar from '../fragments/Toolbar';
import NearPlacesGroupsAccordion from '../fragments/NearPlacesGroupsAccordion';
import AddressLabel from '../fragments/AddressLabel';
import {routes} from '../config/routeConfig';
import {LoadingIndicator} from '../components/LoadingIndicator';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../utils/markerUtils';
import ClassificationDiagram from '../fragments/ClassificationDiagram';
import PlaceShadowAccordion from '../fragments/PlaceShadowAccordion';
import {openReachabilityDialog, setMapFullscreen} from '../store/uiSlice';

interface SidebarSelectedPointProps {
  selectedPoint?: MapPoint | null
}

const PlacesDetailsPage = ({selectedPoint: initialSelectedPoint = null}:SidebarSelectedPointProps) => {
  const dispatch = useDispatch();
  const [selectedPoint, setSelectedPoint] = useState<MapPoint | null>(initialSelectedPoint);
  const favouritePlaces = useSelector((state: RootState) => get(state, 'settings.favouritePlaces', []));
  const map:LeafletMap = useMap();
  const heatmapColorPicker = useHeatmapColorPicker();
  const [address, setAddress] = useState<string>('');
  const params = useParams();
  const navigate = useNavigate();
  const [watchLocation, setWatchLocation] = React.useState<boolean>(false);
  const heatmapBoundingBox: LatLngBoundsLiteral | null = useSelector((state: RootState) => state.map.heatmapMetaData?.boundingBox || null);
  const isAroundMe = useMemo(() => !params.lat && !params.lon, [params.lat, params.lon]);
  const [searchParams, setSearchParams] = useSearchParams();
  const { enqueueSnackbar } = useSnackbar();

  const locateMe = () => {
    if (!heatmapBoundingBox) {
      return;
    }

    map.locate({setView: false, watch: false, enableHighAccuracy: true})
      .once('locationfound', (e) => {
        const {latlng} = e;
        if (L.latLngBounds(heatmapBoundingBox).contains(latlng)) {
          map.flyTo(latlng, map.getZoom() < 12 ? 14 : undefined);
          setSelectedPoint({lat: latlng.lat, lon: latlng.lng});
        } else {
          enqueueSnackbar('You are not in the requested area. Add your location manually.', {variant: 'error'});
          dispatch(openReachabilityDialog());
          setWatchLocation(false);
          map.stopLocate();
          navigate(routes.Startpage.path);
        }
      })
      .once('locationerror', (e) => {
        alert("Couldn't find your location.");
        console.error(e);
        navigate(routes.Startpage.path);
      });
  };

  useEffect(() => {
    dispatch(setMapFullscreen(false));
  }, []);

  useEffect(() => {
    if (params.lat && params.lon) {
      setSelectedPoint({lat: parseFloat(params.lat || '0'), lon: parseFloat(params.lon || '0')});
    } else if (heatmapBoundingBox != null) {
      if (watchLocation) {
        map.stopLocate();
        setWatchLocation(false);
      } else {
        locateMe();
        setWatchLocation(true);
      }
    }
  }, [params?.lat, params?.lon, heatmapBoundingBox]);

  useEffect(() => {
    if (!selectedPoint) {
      return;
    }

    map.flyTo(new LatLng(selectedPoint.lat, selectedPoint.lon), map.getZoom() < 14 ? 14 : undefined);
  }, [selectedPoint, map]);

  const alreadyInFavourites = useMemo(() => selectedPoint != null && favouritePlaces.some((favouritePlace:FavouritePlace) => favouritePlace.lat === selectedPoint.lat && favouritePlace.lon === selectedPoint.lon), [favouritePlaces, selectedPoint]);

  if (!selectedPoint) {
    return (<LoadingIndicator />);
  }

  const handleClose = () => {
    navigate(-1);
    dispatch(setMapFullscreen(true));
  };

  const handleSaveFavourite = () => {
    dispatch(addFavouritePlace({lat: selectedPoint.lat, lon: selectedPoint.lon, name: address}));
  };

  const handleRemoveFavourite = () => {
    dispatch(removeFavouritePlace({lat: selectedPoint.lat, lon: selectedPoint.lon, name: address}));
  };

  const markerIcon = createMarkerIcon(MarkerColor.Red, MarkerSize.Big);

  const headerAdditionalText = isAroundMe ? 'My place' : searchParams.get('name') || '';

  return (
    <>
      {!isAroundMe && (<Marker position={[selectedPoint.lat, selectedPoint.lon]} icon={markerIcon} />)}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Toolbar mapPoint={selectedPoint} alreadyInFavourites={alreadyInFavourites} onClose={handleClose} onSaveFavourite={handleSaveFavourite} onRemoveFavourite={handleRemoveFavourite} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{paddingLeft: '5px', borderLeft: `4px solid ${heatmapColorPicker.getColorAtCoordinate(selectedPoint.lat, selectedPoint.lon)}`}}>
            {headerAdditionalText && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="subtitle1" fontStyle="italic">
                {headerAdditionalText}
              </Typography>
            </Box>
            )}
            <AddressLabel mapPoint={selectedPoint} variant="big" onLoadedAddress={(loadedAddress:string) => setAddress(loadedAddress)} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap="10px" paddingBottom={2}>
            <ClassificationDiagram mapPoint={selectedPoint} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" flexWrap="wrap" gap="10px" paddingBottom={2}>
            <NearPlacesGroupsAccordion mapPoint={selectedPoint} />
            <ReachabilityList lat={selectedPoint.lat} lon={selectedPoint.lon} useAccordion />
            {/* <PlaceShadowAccordion lat={selectedPoint?.lat} lon={selectedPoint?.lon} /> */}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default PlacesDetailsPage;
