/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {
  HeatmapMetaDataResponse,
  HeatmapRequestBody,
  HeatmapStatistics,
  Highlight,
} from '../hooks/map/heatmap';
import {Match} from './uiSlice';

export interface MapPoint {
  lat: number,
  lon: number
}

export interface MapState {
  viewBox: string | null,
  heatmapMetaData: HeatmapMetaDataResponse | null,
  heatmapStatistics: HeatmapStatistics | null,
  lastViewBox: string | null,
  heatmapRequestBody: HeatmapRequestBody | null,
  matches: Match[] | null,
  highlights: Highlight[] | null,
}

const initialState: MapState = {
  viewBox: null,
  heatmapMetaData: null,
  heatmapStatistics: null,
  lastViewBox: null,
  heatmapRequestBody: null,
  matches: null,
  highlights: null,
};

export const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setViewBox: (state, action: PayloadAction<string | null>) => {
      state.viewBox = action.payload !== null ? action.payload : null; // Todo.md testen mit undefined
    },
    setHeatmapMetaData: (state, action: PayloadAction<HeatmapMetaDataResponse | null>) => {
      state.heatmapMetaData = action.payload;
    },
    setHeatmapStatistics: (state, action: PayloadAction<HeatmapStatistics | null>) => {
      state.heatmapStatistics = action.payload;
    },
    setMatches: (state, action: PayloadAction<Match[] | null>) => {
      state.matches = action.payload;
    },
    setLastViewBox: (state, action: PayloadAction<string | null>) => {
      state.lastViewBox = action.payload;
    },
    setHeatmapRequestBody: (state, action: PayloadAction<HeatmapRequestBody | null>) => {
      state.heatmapRequestBody = action.payload;
    },
    setHighlights: (state, action: PayloadAction<Highlight[] | null>) => {
      state.highlights = action.payload;
    },
  },
});

export const {
  setViewBox, setHeatmapMetaData, setHeatmapStatistics, setLastViewBox, setHeatmapRequestBody, setMatches,
  setHighlights,
} = mapSlice.actions;

export default mapSlice.reducer;
