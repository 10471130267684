import L, {Map as LeafletMap, Marker} from 'leaflet';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {ReachabilityPoint} from '../../fragments/Map';
import {openReachabilityDialog} from '../../store/uiSlice';
import {RootState} from '../../store/store';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../../utils/markerUtils';

function createMarkerDiv(reachabilityPoint: ReachabilityPoint) {
  const content = L.DomUtil.create('div', 'map-popup');
  content.innerHTML = `<b>${reachabilityPoint.name}</b>
    <br />
    Reachability criterion: ${reachabilityPoint.travelType} within ${Math.ceil(reachabilityPoint.maxTravelTime / 60)}min 
    <br />
    <a href='#'> > Edit</a>`;
  return content;
}

const createMarker = (map:LeafletMap, dispatch:Function, reachabilityPoint: ReachabilityPoint) => {
  const content = createMarkerDiv(reachabilityPoint);
  L.DomEvent.addListener(content, 'click', () => {
    dispatch(openReachabilityDialog());
    map.closePopup();
  });

  const icon = createMarkerIcon(MarkerColor.Black, MarkerSize.Small);

  const marker = L.marker([reachabilityPoint.lat, reachabilityPoint.lon], { icon }).bindPopup(content);
  marker.addTo(map);
  return marker;
};

export const useReachabilityMarker = (map:LeafletMap | undefined) => {
  const reachabilityPoints = useSelector((state: RootState) => state.settings.reachabilityPoints);
  const dispatch = useDispatch();
  const [markers, setMarkers] = React.useState<Marker[]>([]);

  useEffect(() => {
    if (map === undefined) {
      return;
    }
    markers.forEach((marker) => {
      map.removeLayer(marker);
    });
    const newMarkers = reachabilityPoints.map((reachabilityPoint) => createMarker(map, dispatch, reachabilityPoint));
    setMarkers(newMarkers);
  }, [map, reachabilityPoints]);
};
