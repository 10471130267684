import React, {ReactChild, ReactChildren} from 'react';
import {
  Accordion, AccordionSummary, Box, Paper, Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface ChipPanelProps {
  children: ReactChild,
}

export const ChipBackgroundSx = {
  backgroundColor: 'white',
  borderRadius: {xs: '4px !important'},
  paddingX: '10px',
  '&:hover': {
    backgroundColor: '#ffffff',
  },
  margin: {xs: 0.5, sm: '0'},
  boxShadow: {xs: '', sm: '0 0 1px #555'},
};

function ChipPanel({
  children,
}:ChipPanelProps) {
  return (
    <Box
      sx={ChipBackgroundSx}
    >
      {children}
    </Box>
  );
}

export default ChipPanel;
