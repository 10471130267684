import React, { FC } from 'react';
import { Grid, Chip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useDispatch } from 'react-redux';
import { SatisfactionOption } from './Map';
import { getSatisfactionLabel, getSatisfactionOptionIcon } from '../config/satisfactionOptions';
import { setSelectedSatisfactionOption } from '../store/settingsSlice';

interface SatisfactionChipsProps {
  satisfactionOptions: SatisfactionOption[];
  selectedSatisfactionOption: SatisfactionOption | null;
}

const SatisfactionChips: FC<SatisfactionChipsProps> = ({ satisfactionOptions, selectedSatisfactionOption }) => {
  const dispatch = useDispatch();

  const onSelectionChange = (satisfactionOption: SatisfactionOption | null) => {
    dispatch(setSelectedSatisfactionOption({ satisfactionOption }));
  };

  const renderIcon = (name: string) => {
    const SatisfactionOptionIcon = getSatisfactionOptionIcon(name);
    return <SatisfactionOptionIcon />;
  };

  return (
    <Grid container columnSpacing={3} rowSpacing={2} justifyContent="center">
      {satisfactionOptions.filter((s) => s.forAnalysis).map((satisfactionOption: SatisfactionOption) => {
        if (selectedSatisfactionOption?.id === satisfactionOption.id) {
          return (
            <Grid item key={satisfactionOption.id}>
              <Chip
                icon={renderIcon(satisfactionOption.name)}
                label={getSatisfactionLabel(satisfactionOption.name)}
                variant="filled"
                onClick={undefined}
                deleteIcon={<DeleteIcon />}
                onDelete={() => onSelectionChange(null)}
                sx={{ fontSize: '1.06rem', padding: '1px' }}
              />
            </Grid>
          );
        }

        return (
          <Grid item key={satisfactionOption.id}>
            <Chip
              icon={renderIcon(satisfactionOption.name)}
              label={getSatisfactionLabel(satisfactionOption.name)}
              variant="outlined"
              onClick={() => onSelectionChange(satisfactionOption)}
              deleteIcon={(<> </>)}
              onDelete={undefined}
              sx={{ fontSize: '1.06rem', padding: '4px', paddingX: '11px' }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default SatisfactionChips;
