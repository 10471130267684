import React, {useEffect, useMemo, useState} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Box, Grid, Typography,
} from '@mui/material';
import {LatLng, Map as LeafletMap} from 'leaflet';
import {Marker, useMap} from 'react-leaflet';
import {sortBy} from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PixiOverlay from 'react-leaflet-pixi-overlay';
import {generatePath, useNavigate} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {MapPoint} from '../store/mapSlice';
import PlaceGroupAccordion from './PlaceGroupAccordion';
// import PixiOverlay from '../hooks/map/pixiOverlay';
import ChipAccordion from '../components/ChipAccordion';
import {routes} from '../config/routeConfig';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../utils/markerUtils';
import {setLastClickedPlaceMenuItem} from '../store/uiSlice';

export interface NearPlace extends MapPoint{
  name:string,
  distance:number
}

export interface NearPlacesGroup {
  nearPlaces: NearPlace[],
  type: string,
}

interface NearPlacesResponse {
  nearPlacesGroups: NearPlacesGroup[]
}

interface NearPlacesGroupsAccordionProps {
  mapPoint:MapPoint,
}

const NearPlacesGroupsAccordion = ({
  mapPoint,
}:NearPlacesGroupsAccordionProps) => {
  const map:LeafletMap = useMap();
  const [nearPlacesGroups, setNearPlacesGroups] = useState<NearPlacesGroup[]>([]);
  const [openedNearPlacesGroup, setOpenedNearPlacesGroup] = useState<NearPlacesGroup | null>(null);
  const [selectedNearPlace, setSelectedNearPlace] = useState<NearPlace>();
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loadNearPlaces = async () => {
    const requestUrl:string = `/api/places/near-places?lat=${mapPoint.lat}&lon=${mapPoint.lon}`;
    const response:Response = await fetch(requestUrl);
    const nearPlacesResponse:NearPlacesResponse = await JSON.parse(await response.text());
    setNearPlacesGroups(nearPlacesResponse.nearPlacesGroups);
  };

  useEffect(() => {
    if (!mapPoint) {
      return;
    }
    setNearPlacesGroups([]);
    loadNearPlaces();
  }, [mapPoint]);

  const isNearZoom = () => map != null && map.getZoom() >= 14;

  const pixiMarker:any[] = useMemo(() => {
    if (!openedNearPlacesGroup) {
      return [];
    }

    return openedNearPlacesGroup.nearPlaces.map((match: NearPlace) => {
      const isSelected = selectedNearPlace?.name === match.name;

      let size = 45;
      if (!isSelected) {
        size = isNearZoom() ? 22 : 8;
      }
      return {
        iconColor: `rgba(255,191,252,${isSelected ? 1 : 0.7})`,
        size,
        position: [match.lat, match.lon],
        popup: match?.name,
        popupOpen: false,
        onClick: () => {
          setSelectedNearPlace(match);
          if (map) {
            map.flyTo(new LatLng(match.lat, match.lon), 16);
          }
        },
      };
    });
  }, [openedNearPlacesGroup, map?.getZoom(), map?.getCenter(), selectedNearPlace]);

  const renderMarkers = useMemo(() => {
    if (!isExpanded || !openedNearPlacesGroup) {
      return null;
    }

    const defaultIcon = createMarkerIcon(MarkerColor.Grey, MarkerSize.Small);
    const selectedIcon = createMarkerIcon(MarkerColor.Gold, MarkerSize.Medium);

    return openedNearPlacesGroup.nearPlaces.map((nearPlace) => {
      const isSelected = selectedNearPlace?.name === nearPlace.name;

      return (
        <Marker
          key={nearPlace.name}
          position={[nearPlace.lat, nearPlace.lon]}
          icon={isSelected ? selectedIcon : defaultIcon}
          eventHandlers={{
            click: () => {
              setSelectedNearPlace(nearPlace);
              if (map) {
                map.flyTo(new LatLng(nearPlace.lat, nearPlace.lon), 16);
              }
              dispatch(setLastClickedPlaceMenuItem({
                name: nearPlace.name, lat: nearPlace.lat, lon: nearPlace.lon, type: '',
              }));
            },
          }}
        />
      );
    });
  }, [openedNearPlacesGroup, isExpanded, selectedNearPlace]);

  const onNearPlacesAccordionChanged = (nearPlacesGroup:NearPlacesGroup, isExpanded:boolean) => {
    setOpenedNearPlacesGroup(isExpanded ? nearPlacesGroup : null);
  };

  const renderedPlaceGroupAccordions = useMemo(() => {
    if (!isExpanded) {
      return null;
    }

    const sortedNearPlacesGroup = nearPlacesGroups && sortBy(nearPlacesGroups, 'type').filter((nearPlacesGroup) => nearPlacesGroup?.nearPlaces.length);

    return (
      <>
        { sortedNearPlacesGroup && sortedNearPlacesGroup.map((nearPlacesGroup) => (
          <PlaceGroupAccordion nearPlacesGroup={nearPlacesGroup} onNearPlacesAccordionChanged={onNearPlacesAccordionChanged} key={nearPlacesGroup.type} />))}
      </>
    );
  }, [nearPlacesGroups, isExpanded, selectedNearPlace]);

  return (
    <Box sx={{minWidth: isExpanded ? '100%' : ''}}>
      <ChipAccordion expandedAccordion={isExpanded} onChange={(event, newIsExpanded) => setIsExpanded(newIsExpanded)} title="Surroundings">
        <AccordionDetails sx={{paddingRight: 0, width: '90%'}}>
          { renderedPlaceGroupAccordions }
        </AccordionDetails>
      </ChipAccordion>
      {/* {isExpanded && (<PixiOverlay markers={pixiMarker} />)} */}
      {isExpanded && renderMarkers}
    </Box>
  );
};

export default NearPlacesGroupsAccordion;
