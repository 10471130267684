import React, {SyntheticEvent, useMemo} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Divider, Typography,
} from '@mui/material';
import List from '@mui/material/List';
import {get, sortBy} from 'lodash';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useSelector} from 'react-redux';
import {formatDistance} from '../utils/stringUtils';
import {getSatisfactionLabel, getSatisfactionOption} from '../config/satisfactionOptions';
import PlaceMenuItem from './PlaceMenuItem';
import {NearPlacesGroup} from './NearPlacesGroupsAccordion';
import {RootState} from '../store/store';
import {SatisfactionOption} from './Map';
import {colors} from '../style/colors';

interface PlaceGroupAccordionProps {
  nearPlacesGroup:NearPlacesGroup,
  onNearPlacesAccordionChanged: (nearPlacesGroup: NearPlacesGroup, newIsExpanded:boolean) => void,
}

const PlaceGroupAccordion = ({nearPlacesGroup, onNearPlacesAccordionChanged }:PlaceGroupAccordionProps) => {
  const [isExpanded, setIsExpanded] = React.useState<boolean>(false);
  const satisfactionOptions:SatisfactionOption[] = useSelector((state: RootState) => state.settings.satisfactionOptions);
  const satisfactionOptionUserSettings:SatisfactionOption | undefined = useMemo(() => satisfactionOptions.find((satisfactionOption) => satisfactionOption.id === nearPlacesGroup.type), [nearPlacesGroup]);
  const lastClickedPlaceMenuItem = useSelector((state: RootState) => get(state, 'ui.lastClickedPlaceMenuItem', null));

  const handleAccordionChanged = (event:SyntheticEvent, newIsExpanded:boolean) => {
    setIsExpanded(newIsExpanded);
    onNearPlacesAccordionChanged(nearPlacesGroup, newIsExpanded);
  };

  const renderedSortedNearPlaces = useMemo(() => {
    if (!isExpanded) {
      return null;
    }

    const sortedNearPlaces = nearPlacesGroup?.type === nearPlacesGroup.type && sortBy(nearPlacesGroup.nearPlaces, 'distance');

    return sortedNearPlaces && sortedNearPlaces.map((nearPlace, index) => {
      const nowInvalidDistance = index > 0 && satisfactionOptionUserSettings !== undefined
        && sortedNearPlaces[index - 1].distance < satisfactionOptionUserSettings.satisfactionDistance / 1000 && nearPlace.distance > satisfactionOptionUserSettings.satisfactionDistance / 1000;

      return (
        <>
          {nowInvalidDistance && (<Divider />)}
          <PlaceMenuItem
            name={nearPlace.name}
            suffix={` - ${formatDistance(nearPlace.distance)}`}
            lat={nearPlace.lat}
            lon={nearPlace.lon}
            key={nearPlace.name + nearPlace.lat}
            disableRatingBorder
          />
        </>
      );
    });
  }, [nearPlacesGroup, isExpanded, lastClickedPlaceMenuItem]);

  const getBorderLeftColor = () => {
    if (!satisfactionOptionUserSettings || satisfactionOptionUserSettings.thresholdForMaxSatisfaction === 0) {
      return 'transparent';
    }

    const countWithinDistance = nearPlacesGroup.nearPlaces.filter((nearPlace) => nearPlace.distance <= satisfactionOptionUserSettings.satisfactionDistance / 1000).length;
    if (countWithinDistance >= satisfactionOptionUserSettings.thresholdForMaxSatisfaction) {
      return colors.heatmap.good;
    }
    return colors.heatmap.bad;
  };
  return (
    <Accordion
      expanded={isExpanded}
      onChange={handleAccordionChanged}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
      >
        <Typography variant="body1" sx={{paddingLeft: '8px', borderLeft: `4px solid ${getBorderLeftColor()}`}}>
          {getSatisfactionLabel(nearPlacesGroup.type)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{paddingRight: 0}}>
        <List component="nav" style={{width: '100%'}}>
          {renderedSortedNearPlaces}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

export default PlaceGroupAccordion;
