import React, {useEffect} from 'react';
import './App.css';
import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import {Provider, useDispatch} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';
import {persistStore} from 'redux-persist';
import {StyledEngineProvider, ThemeProvider} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {BrowserRouter} from 'react-router-dom';
import {SnackbarProvider} from 'notistack';
import {store} from './store/store';
import Map from './fragments/Map';
import theme from './style/theme';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

function App() {
  const persistor = persistStore(store);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Provider store={store}>
              <PersistGate loading={null} persistor={persistor}>
                <div>
                  <Map />
                </div>
              </PersistGate>
            </Provider>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
