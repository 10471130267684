import L, {
  LeafletMouseEvent, LocationEvent, Map as LeafletMap, Marker,
} from 'leaflet';
import React, {useEffect} from 'react';
import {useMapEvents} from 'react-leaflet';
import {generatePath, useNavigate} from 'react-router-dom';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../../utils/markerUtils';
import {setLastClickedPlaceMenuItem} from '../../store/uiSlice';
import {routes} from '../../config/routeConfig';

export interface MarkerData {
  lat:number,
  lon:number,
  popupHtml:string
}

export const useMyLocationMarker = (map:LeafletMap | undefined) => {
  const navigate = useNavigate();
  const [myLocationMarker, setMyLocationMarker] = React.useState<Marker | null>(null);

  const locateInterval = React.useRef<NodeJS.Timeout | null>(null);
  const ttt = React.useRef<Marker | null>(null);

  const removeMyLocationMarker = () => {
    if (ttt.current != null) {
      map?.removeLayer(ttt.current);
      ttt.current = null;
    }
  };

  useMapEvents({
    locationfound(event:LocationEvent) {
      // console.log('locationfound', event);
      if (!map) {
        return;
      }
      const {latlng} = event;

      removeMyLocationMarker();
      const icon = createMarkerIcon(MarkerColor.Blue, MarkerSize.Medium);

      const marker = L.marker([latlng.lat, latlng.lng], { icon });
      marker.on('click', (e:LeafletMouseEvent) => {
        e.originalEvent.stopPropagation();
        e.originalEvent.preventDefault();
        navigate(routes.AroundMe.path);
      });
      marker.addTo(map);
      setMyLocationMarker(marker);
      ttt.current = marker;

      // Deactivated due to costs
      // if (!locateInterval.current) {
      //   locateInterval.current = setInterval(() => {
      //     map.locate();
      //     map.removeLayer(marker);
      //   }, 10000);
      // }
    },
  });
};
