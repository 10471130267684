import {createTheme, responsiveFontSizes} from '@mui/material';

const defaultTheme = createTheme();

const theme = createTheme({
  palette:
    {
      ...defaultTheme.palette,
      ...{
        primary: {
          main: '#656565',
        },
        secondary: {
          main: '#aab988',
        },
        warning: {
          main: '#FDE725',
        },
        error: {
          main: '#c90000',
        },
      },
    },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'capitalize',
        },
        contained: {
          color: 'white',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          marginTop: '15px',
          padding: '24px',
        },
      },
    },
    MuiAccordion: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(233,233,233,0)',
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          marginBottom: '10px !important',
        },
        root: {
          '&.Mui-expanded': {
            minHeight: '42px !important',
          },
          minHeight: '42px !important',
          padding: '0px 8px',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: '0px 16px 16px',
        },
      },
    },
  },
});

export default responsiveFontSizes(theme);
