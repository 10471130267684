import React from 'react';
import {Box, Grid} from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import {Link} from 'react-router-dom';

const useStyles = makeStyles(() => ({
  headerLink: {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none',
    },
  },
}));

function Logo() {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      <Grid item xs>
        <Box display="flex" justifyContent="center">
          <Link to="/" className={classes.headerLink}>
            <Box sx={{maxWidth: '300px', height: 'auto'}}>
              <img src="/images/logo.png" style={{width: '100%'}} alt="logo" />
            </Box>
          </Link>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Logo;
