export const formatDistance = (distance:number) => {
  if (distance > 5) {
    return `${distance.toFixed(0)} km`;
  } if (distance > 1) {
    return `${distance.toFixed(1).replace('.0', '')} km`;
  }
  if (distance > 0.5) {
    return `${(Math.round(distance * 10) * 100)} m`;
  }
  return `${(Math.round(distance * 100) * 10)} m`;
};

export const shortBeautiful = (str:string, threshold:number) => {
  if (str.length < threshold) {
    return str;
  }
  return `${str.substr(0, threshold - 2)}..`;
};

export const shortStreet = (place:string) => place?.replace('traße', 'tr.');
