import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import NavigationIcon from '@mui/icons-material/Navigation';
import {useTheme} from '@mui/styles';
import {useSnackbar} from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import {
  closeDialog,
  Dialogs, markFirstPageOpen, openReachabilityDialog,
  openSatisfactionDialog,
  openWelcomeDialog,
  setInvalidRequestedData,
  setLastAddedCoordinate, setMapFullscreen,
} from '../store/uiSlice';
import {RootState} from '../store/store';
import {
  addReachabilityPoint,
  changeReachabilityPointMaxTravelTime,
  changeReachabilityPointTravelType,
  removeReachabilityPoint, setSelectedSatisfactionOption,
} from '../store/settingsSlice';
import {ReachabilityPoint, SatisfactionOption} from '../fragments/Map';
import AutoCompleteLocation, {PlaceType} from '../components/AutoCompleteLocation';
import {calculateDistance} from '../utils/geoUtils';
import {useCanCloseDialog} from '../hooks/canCloseDialog';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

function ReachabilityPointDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [autoCompleteValue, setAutoCompleteValue] = React.useState<PlaceType | null >(null);
  const reachabilityDialogOpen:boolean = useSelector((state: RootState) => get(state, 'ui.openDialog') === Dialogs.Reachability);
  const satisfactionOptions:SatisfactionOption[] = useSelector((state: RootState) => state.settings.satisfactionOptions.filter((satisfactionOption) => satisfactionOption.thresholdForMaxSatisfaction > 0));
  const invalidRequestedData:boolean = useSelector((state: RootState) => get(state, 'ui.invalidRequestedData', false));
  const reachabilityPoints:ReachabilityPoint[] = useSelector((state: RootState) => get(state, 'settings.reachabilityPoints', []));
  const [addingAutocomplete, setAddingAutocomplete] = useState<boolean>(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { enqueueSnackbar } = useSnackbar();
  const canClose:boolean = useCanCloseDialog();
  const markFirstPageOpened:boolean = useSelector((state: RootState) => get(state, 'ui.markFirstPageOpen', false));
  const selectedSatisfactionOption:SatisfactionOption | null = useSelector((state: RootState) => state.settings.selectedSatisfactionOption);

  useEffect(() => {
    if (!markFirstPageOpened) {
      dispatch(openWelcomeDialog());
      dispatch(markFirstPageOpen());
    }
  }, []);

  const handleClose = () => {
    if (!canClose) {
      return;
    }
    dispatch(closeDialog());
    dispatch(setMapFullscreen(true));
  };

  const handleBack = () => {
    dispatch(closeDialog());
    dispatch(openWelcomeDialog());
  };

  const handleApply = () => {
    handleClose();
  };

  const preloadReachability = () => {
    axios.post('/api/heatmap/preload-reachability', {reachabilityPoints});
  };

  const handleNext = () => {
    handleApply();
    if (satisfactionOptions.length === 0) {
      preloadReachability();
    }
    dispatch(openSatisfactionDialog());
    dispatch(setInvalidRequestedData(false));
  };

  const onChangeTravelType = (reachabilityPoint:ReachabilityPoint, selectChangeEvent:SelectChangeEvent) => {
    dispatch(changeReachabilityPointTravelType({id: reachabilityPoint.id, travelType: selectChangeEvent.target.value}));
    if (selectChangeEvent.target.value === 'radius') {
      dispatch(changeReachabilityPointMaxTravelTime({id: reachabilityPoint.id, maxTravelTime: 2}));
    } else if (reachabilityPoint.travelType === 'radius') {
      dispatch(changeReachabilityPointMaxTravelTime({id: reachabilityPoint.id, maxTravelTime: 60 * 5}));
    }
    dispatch(setInvalidRequestedData(false));
  };

  const onChangeMaxTravelTime = (id:number, selectChangeEvent:SelectChangeEvent) => {
    dispatch(changeReachabilityPointMaxTravelTime({id, maxTravelTime: parseInt(selectChangeEvent.target.value, 10)}));
    dispatch(setInvalidRequestedData(false));
  };

  const onClickDelete = (id:number) => {
    dispatch(removeReachabilityPoint(id));
    dispatch(setInvalidRequestedData(false));
  };

  const renderDeleteButton = (reachabilityPoint:ReachabilityPoint) => (
    <IconButton color="primary" onClick={() => onClickDelete(reachabilityPoint.id)} size="small">
      <DeleteIcon />
    </IconButton>
  );

  const renderLimitSelectbox = useCallback((reachabilityPoint : ReachabilityPoint) => {
    if (reachabilityPoint.travelType === 'radius') {
      return (
        <FormControl className={classes.formControl} fullWidth>
          <InputLabel id={`labelLimit${reachabilityPoint.name}`}>Radius</InputLabel>
          <Select
            labelId={`labelLimit${reachabilityPoint.name}`}
            value={`${reachabilityPoint.maxTravelTime}`}
            onChange={(selectChangeEvent:SelectChangeEvent) => onChangeMaxTravelTime(reachabilityPoint.id, selectChangeEvent)}
            label="Radius"
            fullWidth
            size="small"
          >
            <MenuItem value={2}>2 kilometre</MenuItem>
            <MenuItem value={3}>3 kilometre</MenuItem>
            <MenuItem value={5}>5 kilometre</MenuItem>
            <MenuItem value={10}>10 kilometre</MenuItem>
            <MenuItem value={15}>15 kilometre</MenuItem>
            <MenuItem value={20}>20 kilometre</MenuItem>
            <MenuItem value={30}>30 kilometre</MenuItem>
          </Select>
        </FormControl>
      );
    }
    return (
      <FormControl className={classes.formControl} fullWidth>
        <InputLabel id={`labelLimit${reachabilityPoint.name}`}>Time limit</InputLabel>
        <Select
          labelId={`labelLimit${reachabilityPoint.name}`}
          value={`${reachabilityPoint.maxTravelTime}`}
          onChange={(selectChangeEvent:SelectChangeEvent) => onChangeMaxTravelTime(reachabilityPoint.id, selectChangeEvent)}
          label="Time limit"
          fullWidth
          size="small"
        >
          <MenuItem value={60 * 5}>10 Minuten</MenuItem>
          <MenuItem value={60 * 15}>20 Minuten</MenuItem>
          <MenuItem value={60 * 30}>30 Minuten</MenuItem>
          <MenuItem value={60 * 40}>40 Minuten</MenuItem>
          <MenuItem value={60 * 50}>50 Minuten</MenuItem>
          <MenuItem value={60 * 60}>1 Stunde</MenuItem>
          <MenuItem value={60 * 90}>1,5 Stunden</MenuItem>
          <MenuItem value={60 * 120}>2 Stunden</MenuItem>
        </Select>
      </FormControl>
    );
  }, [reachabilityPoints]);

  const renderTable = () => (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          {reachabilityPoints.map((reachabilityPoint) => (
            <TableRow key={reachabilityPoint.id}>
              <TableCell>
                <Grid
                  container
                  columnSpacing={2}
                  rowSpacing={1}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item xs={10} md={5}>
                    <Typography variant={reachabilityPoint.maxTravelTime > 0 ? 'body1' : 'body2'}>
                      <Link href={`https://www.google.com/maps/place/${reachabilityPoint.name}/@${reachabilityPoint.lat},${reachabilityPoint.lon},16z`} target="_blank">
                        {reachabilityPoint.name}
                      </Link>
                    </Typography>
                  </Grid>
                  <Grid item xs={2} sx={{display: {xs: 'inherit', md: 'none'}}}>
                    {renderDeleteButton(reachabilityPoint)}
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <FormControl className={classes.formControl} fullWidth>
                      <InputLabel id={`labelMode${reachabilityPoint.id}`}>Typ</InputLabel>
                      <Select
                        labelId={`labelMode${reachabilityPoint.id}`}
                        value={reachabilityPoint.travelType}
                        onChange={(selectChangeEvent:SelectChangeEvent) => onChangeTravelType(reachabilityPoint, selectChangeEvent)}
                        label="Typ"
                        size="small"
                      >
                        <MenuItem value="radius">Radius</MenuItem>
                        <MenuItem value="car">Car</MenuItem>
                        <MenuItem value="transit">Transit</MenuItem>
                        <MenuItem value="bike">Bike</MenuItem>
                        <MenuItem value="walk">Walking</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} md={3}>
                    {renderLimitSelectbox(reachabilityPoint)}
                  </Grid>
                  <Grid item md={1} sx={{display: {xs: 'none', md: 'inherit'}}}>
                    <Box display="flex" justifyContent="flex-end" sx={{width: '100%'}}>
                      {renderDeleteButton(reachabilityPoint)}
                    </Box>
                  </Grid>
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const checkNewReachabilityPointHasValidDistance = (lat: number, lon:number) => reachabilityPoints.length === 0
    || !reachabilityPoints.some((reachabilityPoint) => calculateDistance(lat, lon, reachabilityPoint.lat, reachabilityPoint.lon) > 250);

  const onClickAddAutoComplete = async () => {
    if (autoCompleteValue == null || reachabilityPoints.some((reachabilityPoint) => reachabilityPoint.name === autoCompleteValue.description)) {
      return;
    }
    const requestUrl:string = `/api/places/details?place_id=${autoCompleteValue.placeId}`;
    setAddingAutocomplete(true);
    const response:Response = await fetch(requestUrl);
    setAddingAutocomplete(false);

    const placeDetailsResponse:any = JSON.parse(await response.text());
    setAutoCompleteValue(null);

    if (!checkNewReachabilityPointHasValidDistance(placeDetailsResponse.lat, placeDetailsResponse.lng)) {
      enqueueSnackbar(`'${autoCompleteValue.description}' is too far away. Remove list entries or choose a nearer location.`, {variant: 'error'});
      return;
    }

    const newReachabilityPoint:ReachabilityPoint = {
      lat: placeDetailsResponse.lat,
      lon: placeDetailsResponse.lng,
      maxTravelTime: 5,
      travelType: 'radius',
      name: autoCompleteValue.description,
      id: reachabilityPoints.length === 0 ? 1 : Math.max(...reachabilityPoints.map((r) => r.id)) + 1,
    };

    dispatch(addReachabilityPoint(newReachabilityPoint));
    dispatch(setLastAddedCoordinate({lat: newReachabilityPoint.lat, lon: newReachabilityPoint.lon}));
  };

  const renderAddForm = () => (
    <Grid container rowSpacing={1} columnSpacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={8} md={9}>
        <AutoCompleteLocation setValue={setAutoCompleteValue} value={autoCompleteValue} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <Box display="flex">
          <Button
            size="medium"
            disabled={addingAutocomplete || !autoCompleteValue}
            fullWidth
            variant={autoCompleteValue != null ? 'contained' : 'outlined'}
            onClick={onClickAddAutoComplete}
            endIcon={(<AddIcon />)}
          >
            Add location
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Dialog
      open={reachabilityDialogOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      BackdropProps={{sx: {backgroundColor: 'rgba(255, 255, 255, 0.96)'}}}
      PaperProps={{
        elevation: 0,
        sx: fullScreen ? {maxWidth: {xs: '100% !important', sm: 'inherit'}, width: {xs: '100% !important', sm: 'inherit'}, margin: 0} : null,
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            {/* <NavigationIcon sx={{fontSize: '1.6rem', marginRight: 2}} /> */}
            <Typography variant="h5">
              Location analysis
            </Typography>
          </Box>
          {canClose && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Around which locations should the analysis be carried out?
            </Typography>
            <Typography variant="subtitle2">
              Only areas that can be reached from all locations as specified are analyzed.
            </Typography>
          </Grid>
          {invalidRequestedData && (
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="error">
                Error! It may be that no area overlaps could be found with this information.
              </Typography>
            </Grid>
          )}
          {reachabilityPoints.length > 0 && (
            <Grid item xs={12}>
              {renderTable()}
            </Grid>
          )}
          <Grid item xs={12}>
            {renderAddForm()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="center">
          {/* <Button onClick={handleBack} color="primary" variant="outlined"> */}
          {/*  Info */}
          {/* </Button> */}
          {reachabilityPoints.length > 0 ? (
            <>
              {selectedSatisfactionOption === null
                ? (
                  <Button onClick={handleNext} color="primary" variant="contained">
                    Next step
                  </Button>
                )
                : (
                  <Button onClick={handleClose} color="primary" variant="contained">
                    Save and close
                  </Button>
                )}
            </>
          ) : null}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ReachabilityPointDialog;
