import React, {useEffect, useState} from 'react';
import {
  Box, Grid, IconButton, Tooltip, Typography,
} from '@mui/material';
import {formatDistance, shortBeautiful, shortStreet} from '../utils/stringUtils';
import {ReachabilityPoint} from './Map';
import {getTravelTypeIcon, travelTypes} from '../config/travelTypes';
import {colors} from '../style/colors';

interface ReachabilityListEntryProps {
  reachabilityPoint: ReachabilityPoint,
  lat:number,
  lon:number,
}

interface ReachabilityDataResponse {
  duration:number,
  distance:number
}

const ReachabilityListEntry = ({reachabilityPoint, lat, lon}:ReachabilityListEntryProps) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(0);
  const [distance, setDistance] = useState<number>(0);
  const [travelType, setTravelType] = useState<string>(reachabilityPoint.travelType);

  const requestTravelData = async () => {
    setLoading(true);
    const requestUrl:string = `/api/places/reachability-data-for-point?targetLat=${lat}&targetLon=${lon}&originLat=${reachabilityPoint.lat}&originLon=${reachabilityPoint.lon}&travelType=${travelType}`;

    fetch(requestUrl).then((response:Response) => response.text()).then((data:string) => JSON.parse(data)).then((travelDataResponse:ReachabilityDataResponse) => {
      setDistance(travelDataResponse.distance);
      setDuration(travelDataResponse.duration);
    })
      .catch((error) => {
        console.log(error);
        setDistance(0);
        setDuration(0);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    requestTravelData();
  }, [travelType, lat, lon, reachabilityPoint]);

  const clickSwitchTravelType = () => {
    const currentIndex = travelTypes.indexOf(travelType);
    let nextIndex = currentIndex + 1;
    if (nextIndex >= travelTypes.length) {
      nextIndex = 0;
    }
    setTravelType(travelTypes[nextIndex]);
  };

  const renderTravelIcon = () => {
    const TravelIcon = getTravelTypeIcon(travelType);
    return <TravelIcon sx={{fontSize: '1.3rem', marginTop: '-4px'}} />;
  };

  const getReachabilityDataColor = () : string => {
    if (reachabilityPoint.travelType !== travelType) {
      return colors.heatmap.outside;
    }
    if ((travelType === 'radius' && distance < reachabilityPoint.maxTravelTime * 1000)
        || (travelType !== 'radius' && duration < reachabilityPoint.maxTravelTime)) {
      return colors.heatmap.good;
    }
    return colors.heatmap.bad;
  };

  const renderReachabilityData = () => {
    if (loading) {
      return (
        <Typography variant="body2" align="right">
          Laden..
        </Typography>
      );
    }

    if (!duration) {
      return (
        <Typography variant="body2" align="right">
          -
        </Typography>
      );
    }

    return (
      <Typography variant="body1" align="right">
        {duration > 0 && `${Math.round(duration / 60)} min    -   `}
        {`${formatDistance(distance / 1000.0)}`}
      </Typography>
    );
  };

  return (
    <Grid container>
      <Grid item xs={5} sx={{paddingLeft: '8px', borderLeft: `8px solid ${getReachabilityDataColor()}`}}>
        {reachabilityPoint.name.length > 23 ? (
          <Tooltip title={reachabilityPoint.name} enterDelay={0}>
            <Box>
              {shortBeautiful(shortStreet(reachabilityPoint.name), 20)}
            </Box>
          </Tooltip>
        ) : (
          <Box>
            {shortBeautiful(shortStreet(reachabilityPoint.name), 20)}
          </Box>
        )}
      </Grid>
      <Grid item xs={5}>
        {renderReachabilityData()}
      </Grid>
      <Grid item xs={2}>
        {/* Advice: Select doesnt work here */}
        <Box display="flex" justifyContent="end">
          <IconButton onClick={clickSwitchTravelType} size="small">
            {renderTravelIcon()}
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReachabilityListEntry;
