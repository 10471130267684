import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import NavigationIcon from '@mui/icons-material/Navigation';
import ShareIcon from '@mui/icons-material/Share';
import PlaceIcon from '@mui/icons-material/Place';
import {useTheme} from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import {
  closeDialog,
  Dialogs,
  markFirstPageOpen,
  openReachabilityDialog,
  openWelcomeDialog,
} from '../store/uiSlice';
import {RootState} from '../store/store';
import {useCanCloseDialog} from '../hooks/canCloseDialog';
import {explanations} from '../config/explanations';
import {ReachabilityPoint, SatisfactionOption} from '../fragments/Map';
import {routes} from '../config/routeConfig';

function WelcomeDialog() {
  const dispatch = useDispatch();
  const [mapShareId, setMapShareId] = useState<string | null>();
  const welcomeDialogOpen:boolean = useSelector((state: RootState) => get(state, 'ui.openDialog') === Dialogs.Welcome);
  const satisfactionOptions:SatisfactionOption[] = useSelector((state: RootState) => state.settings.satisfactionOptions);
  const selectedSatisfactionOptions:SatisfactionOption[] = useMemo(() => satisfactionOptions.filter((satisfactionOption) => satisfactionOption.thresholdForMaxSatisfaction > 0), [satisfactionOptions]);
  const reachabilityPoints:ReachabilityPoint[] = useSelector((state: RootState) => get(state, 'settings.reachabilityPoints', []));
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const canClose:boolean = useCanCloseDialog();

  useEffect(() => {
    setMapShareId(null);
  }, [selectedSatisfactionOptions, reachabilityPoints]);

  const handleClose = () => {
    if (!canClose) {
      return;
    }
    dispatch(closeDialog());
  };

  const handleApply = () => {
    handleClose();
  };

  const handleNext = () => {
    handleApply();
    dispatch(openReachabilityDialog());
  };

  const handleOpenShareAccordion = async () => {
    if (mapShareId != null) {
      return;
    }
    const content = JSON.stringify({reachabilityPoints, selectedSatisfactionOptions});
    const response = await axios.post('/api/map-share', {content});
    setMapShareId(response?.data?.id);
  };

  const hasFilledAllNecessaryData = selectedSatisfactionOptions.length > 0 && reachabilityPoints.length > 0;

  const getMapShareUrl = useCallback(() => {
    if (!mapShareId) {
      return 'Lädt..';
    }
    return `https://${window.location.host}${routes.MapShare.path.replace(':id', mapShareId)}`;
  }, [mapShareId]);

  return (
    <Dialog
      open={welcomeDialogOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      BackdropProps={{sx: {backgroundColor: 'rgba(255, 255, 255, 0.96)'}}}
      PaperProps={{
        elevation: 0,
        sx: fullScreen ? {maxWidth: {xs: '100% !important', sm: 'inherit'}, width: {xs: '100% !important', sm: 'inherit'}, margin: 0} : null,
      }}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box sx={{maxWidth: '100px', height: 'auto'}}>
            <img src="/images/logo.png" style={{width: '100%'}} alt="logo" />
          </Box>
          {canClose && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Get a quick overview with an individually tailored area analysis
        </DialogContentText>
        <Grid container spacing={2} my={4}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} alignItems="center" textAlign="center">
                    <NavigationIcon sx={{fontSize: '4.0rem'}} htmlColor="grey" />
                  </Grid>
                  <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="body1">
                      Reachability
                    </Typography>
                  </Grid>
                  <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="body2">
                      Limit the area based on accessibility or radius
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12} alignItems="center" textAlign="center">
                    <PlaceIcon sx={{fontSize: '4.0rem'}} htmlColor="grey" />
                  </Grid>
                  <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="body1">
                      Surroundings
                    </Typography>
                  </Grid>
                  <Grid item xs={12} alignItems="center" textAlign="center">
                    <Typography variant="body2">
                      Shopping street, party district or quite residential area? Analyze the environment
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!hasFilledAllNecessaryData && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" width="100%" marginTop={8} marginBottom={8}>
              <Button onClick={handleNext} color="primary" variant="contained">
                Start now!
              </Button>
            </Box>
          </Grid>
          )}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography>About</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography>
                      This application displays analysis based on publicly available data, helping to get a quick overview of an area.
                      {' '}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Explanation:
                    </Typography>
                    <Typography>
                      {explanations.map((explanation) => (
                        <React.Fragment key={Math.random()}>
                          {explanation}
                        </React.Fragment>
                      ))}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h6">
                      Contact:
                    </Typography>
                    <Typography variant="body1">
                      <Link href="mailto:admin@yourtravelitinerary.com">
                        admin@yourtravelitinerary.com
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
            {false && hasFilledAllNecessaryData && (
              <Accordion onChange={(event, isExpanded) => isExpanded && handleOpenShareAccordion()}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Box display="flex" alignItems="center">
                    <ShareIcon sx={{fontSize: '1.6rem', marginRight: 2}} />
                    <Typography variant="body1">
                      Share current map
                    </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="body1">
                        Share this link so others can see all your settings and the map they generate.
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Dein persönlicher Link"
                        value={getMapShareUrl()}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        Attention: This means that others will see the addresses you have currently entered for accessibility purposes.
                      </Typography>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      {hasFilledAllNecessaryData && (
        <DialogActions>
          <Grid container>
            <Grid item xs={0} md={6} />
            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="flex-end" width="100%" marginTop={1}>
                <Button onClick={handleClose} color="primary" variant="contained">
                  Back to map
                </Button>
              </Box>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  );
}

export default WelcomeDialog;
