import React from 'react';
import L, {LatLng, Map as LeafletMap, MarkerCluster} from 'leaflet';
import {Marker, useMap} from 'react-leaflet';
import {useSelector} from 'react-redux';
import {generatePath, useNavigate} from 'react-router-dom';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import {Match} from '../store/uiSlice';
import {RootState} from '../store/store';
import {routes} from '../config/routeConfig';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../utils/markerUtils';

const MatchesMarkers = () => {
  const map:LeafletMap = useMap();

  const matches: Match[] = useSelector((state: RootState) => state.map.matches || []);
  const navigate = useNavigate();

  const markerIcon = createMarkerIcon(MarkerColor.Grey, MarkerSize.Medium);

  const handleMarkerClick = (match:Match) => {
    map.flyTo(new LatLng(match.lat, match.lon), map.getZoom() < 15 ? 15 : undefined);
    navigate(generatePath(routes.MatchDetails.path, {id: `${match.id}`}));
  };

  const createClusterCustomIcon = (cluster:MarkerCluster) => L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: 'marker-cluster-custom',
    iconSize: L.point(40, 40, true),
  });

  return (
    <MarkerClusterGroup showCoverageOnHover={false} animate iconCreateFunction={createClusterCustomIcon} maxClusterRadius={160}>
      {matches.map((match:Match) => (
        <Marker
          key={match.id}
          position={[match.lat, match.lon]}
          icon={markerIcon}
          eventHandlers={{
            click: () => handleMarkerClick(match),
          }}
        />
      ))}
    </MarkerClusterGroup>
  );
};

export default MatchesMarkers;
