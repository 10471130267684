import React from 'react';
import {
  Box,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, useMediaQuery,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {get} from 'lodash';
import {useTheme} from '@mui/styles';
import {
  closeDialog,
  closeReplacementReachabilityDialog,
  setLastAddedCoordinate,
} from '../store/uiSlice';
import {RootState} from '../store/store';
import {ReachabilityPoint} from '../fragments/Map';
import {
  addReachabilityPoint,
  clearReachabilityPoints,
} from '../store/settingsSlice';

function ReplacementReachabilityDialog() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const replacementReachability:ReachabilityPoint | null = useSelector((state: RootState) => get(state, 'ui.replacementReachability', null));
  const clearReplacementPlace = () => {
    dispatch(closeReplacementReachabilityDialog());
  };

  const doReplacementPlace = () => {
    const newReachabilityPoint:ReachabilityPoint = {
      lat: replacementReachability!.lat,
      lon: replacementReachability!.lon,
      maxTravelTime: 5,
      travelType: 'radius',
      name: replacementReachability!.name,
      id: Math.random() * 100000,
    };

    dispatch(clearReachabilityPoints());
    dispatch(addReachabilityPoint(newReachabilityPoint));
    dispatch(setLastAddedCoordinate({lat: newReachabilityPoint.lat, lon: newReachabilityPoint.lon}));
    dispatch(closeDialog());
    dispatch(closeReplacementReachabilityDialog());
  };

  return (
    <Dialog
      onClose={clearReplacementPlace}
      open={replacementReachability !== null}
      transitionDuration={{enter: 200, exit: 200}}
      maxWidth="md"
      scroll="body"
      BackdropProps={{sx: {backgroundColor: 'rgba(255, 255, 255, 0.96)'}}}
      PaperProps={{
        elevation: 0,
        sx: fullScreen ? {maxWidth: {xs: '100% !important', sm: 'inherit'}, width: {xs: '100% !important', sm: 'inherit'}, margin: 0} : null,
      }}
    >
      <DialogTitle>
        New location area
      </DialogTitle>
      <DialogContent>
        <Typography>
          Location analysis for
          {` '${replacementReachability?.name || '______'}' `}
          ?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Button onClick={clearReplacementPlace} color="primary" variant="outlined">
            Cancel
          </Button>
          <Button onClick={doReplacementPlace} color="primary" variant="contained">
            Replace
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default ReplacementReachabilityDialog;
