import L, {
  LatLng, LatLngBounds, PointExpression, PointTuple,
} from 'leaflet';

export const getLatLngBounds = (boundingBoxString:string) : LatLngBounds => {
  const parts:number[] = boundingBoxString.split(',').map((number) => Number(number));
  return new LatLngBounds(new LatLng(parts[1], parts[0]), new LatLng(parts[3], parts[2]));
};

export enum MarkerColor {
  Red = 'red',
  Gold = 'gold',
  Blue = 'blue',
  Green = 'green',
  Orange = 'orange',
  Yellow = 'yellow',
  Violet = 'violet',
  Grey = 'grey',
  Black = 'black',
}

export enum MarkerSize {
  Small,
  Medium,
  Big,
}

interface IconSizeAttribute {
  iconSize: PointExpression | undefined,
  iconAnchor: PointTuple,
  popupAnchor: PointExpression | undefined,
  shadowSize: PointExpression | undefined,
}

const getSizeAttributes = (size:MarkerSize):IconSizeAttribute => {
  switch (size) {
    case MarkerSize.Small:
      return {
        iconSize: [12, 21],
        iconAnchor: [6, 21],
        popupAnchor: [1, -17],
        shadowSize: [21, 21],
      };
    case MarkerSize.Big:
      return {
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      };
    case MarkerSize.Medium:
    default:
      return {
        iconSize: [25 * 0.8, 41 * 0.8],
        iconAnchor: [12 * 0.8, 41 * 0.8],
        popupAnchor: [1 * 0.8, -34 * 0.8],
        shadowSize: [41 * 0.8, 41 * 0.8],
      };
  }
};

export const createMarkerIcon = (color:MarkerColor, size:MarkerSize) => {
  const {
    iconSize, iconAnchor, popupAnchor, shadowSize,
  } = getSizeAttributes(size);

  return new L.Icon({
    iconUrl: `/images/markers/marker-icon-2x-${color}.png`,
    shadowUrl: '/images/markers/marker-shadow.png',
    iconSize,
    iconAnchor,
    popupAnchor,
    shadowSize,
  });
};
