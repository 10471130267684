import React, {useMemo, useState} from 'react';
import {
  Accordion, AccordionDetails, AccordionSummary, Grid, Typography,
} from '@mui/material';
import {useSelector} from 'react-redux';
import List from '@mui/material/List';
import {RootState} from 'store/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Map as LeafletMap} from 'leaflet';
import {useMap} from 'react-leaflet';
import {orderBy} from 'lodash';
import {LocationIndicatorEntryArea, locationIndicatorTypes} from '../config/locationIndicators';
import PlaceMenuItem from './PlaceMenuItem';
import {usePlacesMarker} from '../hooks/map/placesMarker';
import {MarkerColor} from '../utils/markerUtils';

export interface LocationIndicatorEntry {
  lat: number,
  lon: number,
  name: string,
  value: number,
}

export interface LocationIndicatorGroup {
  locationIndicatorEntries: LocationIndicatorEntry[],
  type: string
}

function LocationsByIndicatorAccordion() {
  const locationIndicatorGroups = useSelector((state: RootState) => state.map.heatmapStatistics?.locationIndicatorGroups || []);
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(false);
  const map: LeafletMap = useMap();

  const selectedMarkers: LocationIndicatorEntry[] | undefined = expandedAccordion ? locationIndicatorGroups?.find((locationIndicatorGroup:LocationIndicatorGroup) => locationIndicatorGroup.type === locationIndicatorTypes.BEST_AREA.backendIdentifier)?.locationIndicatorEntries : undefined;

  const markerData = useMemo(() => selectedMarkers?.map((marker) => ({
    lat: marker.lat, lon: marker.lon, popupHtml: `<b>${marker.name}</b>
      <br />
    <a href='#'> > Details</a>`,
  })), [selectedMarkers]);

  usePlacesMarker(map, markerData, MarkerColor.Red);

  const renderLocationIndicatorGroupContent = (locationIndicatorGroup: LocationIndicatorGroup) => {
    const locationIndicatorEntries = orderBy(locationIndicatorGroup.locationIndicatorEntries, ['value'], ['desc']);

    return (
      <List component="nav" style={{width: '100%'}}>
        {locationIndicatorEntries.map((locationIndicatorEntry, index) => (
          <PlaceMenuItem
            key={locationIndicatorEntry.name + locationIndicatorEntry.value}
            name={locationIndicatorEntry.name}
            prefix={`${index + 1}. `}
            lat={locationIndicatorEntry.lat}
            lon={locationIndicatorEntry.lon}
          />
        ))}
      </List>
    );
  };

  const renderLocationIndicatorGroup = (locationIndicatorEntryArea: LocationIndicatorEntryArea) => {
    const selectedGroup = locationIndicatorGroups?.find((locationIndicatorGroup:LocationIndicatorGroup) => locationIndicatorGroup.type === locationIndicatorEntryArea.backendIdentifier);

    if (!selectedGroup) {
      return (<> </>);
    }

    return (
      <Grid item xs={12}>
        <Accordion onChange={(event, isExpanded) => setExpandedAccordion(isExpanded)}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography variant="body1">
              {locationIndicatorEntryArea.label}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {renderLocationIndicatorGroupContent(selectedGroup)}
          </AccordionDetails>
        </Accordion>
      </Grid>
    );
  };

  return (
    <>
      {renderLocationIndicatorGroup(locationIndicatorTypes.BEST_AREA)}
    </>
  );
}

export default LocationsByIndicatorAccordion;
