import React from 'react';
import {Box, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import {RootState} from '../store/store';
import {LoadingHeatmapState} from '../store/uiSlice';
import {LoadingIndicator} from '../components/LoadingIndicator';
import {explanations} from '../config/explanations';

function LoadingHeatmapIndicator() {
  const loadingHeatmapState:LoadingHeatmapState = useSelector((state: RootState) => get(state, 'ui.loadingHeatmapState', false));

  const getLoadingText = () => {
    switch (loadingHeatmapState) {
      case LoadingHeatmapState.Error:
        return 'Loading error';
      case LoadingHeatmapState.MetaDataRequesting:
        return 'Your area will be measured..';
      case LoadingHeatmapState.HeatmapRequesting:
        return 'Places are brought together';
      case LoadingHeatmapState.ImageRequesting:
        return 'Image is drawn';
      case LoadingHeatmapState.Finished:
        return 'Ready!';
      default:
        return 'Loading..';
    }
  };

  const renderExplanation = () => explanations[0];

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      width="100%"
      marginBottom={4}
    >
      <LoadingIndicator />
      <Box p={3}>
        <Typography variant="h5" align="center">
          {getLoadingText()}
        </Typography>
      </Box>
      <Box py={3}>
        <Typography variant="subtitle2" align="center">
          Initial creation takes up to a minute
        </Typography>
      </Box>
      <Box pt={6} pb={3} px={2}>
        <Typography variant="body2" align="center">
          {renderExplanation()}
        </Typography>
      </Box>
    </Box>
  );
}

export default LoadingHeatmapIndicator;
