import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash/debounce';
import axios from 'axios';
import {useSelector} from 'react-redux';
import {get} from 'lodash';
import {Box, Button, IconButton} from '@mui/material';
import {GpsFixed} from '@mui/icons-material';
import {LatLng, Map as LeafletMap} from 'leaflet';
import {useMap} from 'react-leaflet';
import AddIcon from '@mui/icons-material/Add';
import {RootState} from '../store/store';
import {MapPoint} from '../store/mapSlice';

const useStyles = makeStyles(() => ({
  icon: {
    // color: theme.text.secondary,
    marginRight: 2,
  },
}));

export interface PlaceType {
  description: string;
  placeId:string,
  structuredFormatting: {
    mainText: string;
    secondaryText: string;
    mainTextMatchedSubstrings: [
      {
        offset: number;
        length: number;
      },
    ];
  };
}

interface AutoCompleteLocationProps {
  setValue: Function,
  value: PlaceType | null,
}

const AutoCompleteLocation = ({setValue, value}:AutoCompleteLocationProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<PlaceType[]>([]);
  const loaded = React.useRef(false);
  const lastAddedCoordinate: MapPoint | null = useSelector((state: RootState) => get(state, 'ui.lastAddedCoordinate', null));
  const map:LeafletMap = useMap();

  if (typeof window !== 'undefined' && !loaded.current) {
    loaded.current = true;
  }

  const getAutocomplete = async (input:any) => {
    const response = await axios.post('/api/places/autocomplete', input);
    return response.data;
  };

  const fetchAutocomplete = React.useMemo(
    () => debounce((request: { input:string, location:MapPoint | null }, callback: (results?: PlaceType[]) => void) => {
      getAutocomplete(request).then((results:any) => {
        callback(results);
      });
    }, 600),
    [],
  );

  const requestLocation = async (latlng: LatLng) => {
    const requestUrl:string = `/api/places/autocomplete-by-coordinates?lat=${latlng.lat}&lon=${latlng.lng}`;
    const response:Response = await fetch(requestUrl);
    const requestedAddress:PlaceType = JSON.parse(await response.text()) as PlaceType;
    setValue(requestedAddress);
  };

  const handleLocateMe = () => {
    map.locate()
      .once('locationfound', (e) => {
        const {latlng} = e;
        requestLocation(latlng);
      })
      .on('locationerror', (e) => {
        console.error(e);
      });
  };

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchAutocomplete({ input: inputValue, location: lastAddedCoordinate}, (results?: PlaceType[]) => {
      if (active) {
        let newOptions = [] as PlaceType[];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Box display="flex" alignContent="center">
      {/* <IconButton */}
      {/*  onClick={handleLocateMe} */}
      {/*  sx={{ */}
      {/*    mr: 1, */}
      {/*    display: {xs: 'flex', sm: 'none'}, */}
      {/*  }} */}
      {/* > */}
      {/*  <GpsFixed /> */}
      {/* </IconButton> */}
      <Button
        size="medium"
        variant="outlined"
        onClick={handleLocateMe}
        startIcon={(<GpsFixed />)}
        sx={{
          mr: 1,
          minWidth: '50px',
          // display: {xs: 'none', sm: 'flex'},
        }}
      >
        Me
      </Button>
      <Autocomplete
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        filterOptions={(x) => x}
        options={options}
        fullWidth
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        size="small"
        noOptionsText="..."
        onChange={(event: any, newValue: PlaceType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Location.."
            variant="outlined"
            fullWidth
            margin="none"
            placeholder="Examples: 'Berlin Central Station', 'Cologne Cathedral'"
          />
        )}
        renderOption={(props, option) => {
          const matches = option.structuredFormatting.mainTextMatchedSubstrings;
          const parts = parse(
            option.structuredFormatting.mainText,
            matches.map((match: any) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <LocationOnIcon className={classes.icon} />
                </Grid>
                <Grid item xs>
                  {parts.map((part) => (
                    <span key={part.text} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                      {part.text}
                    </span>
                  ))}
                  <Typography variant="body2" color="textSecondary">
                    {option.structuredFormatting.secondaryText}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </Box>
  );
};

export default AutoCompleteLocation;
