import React from 'react';
import {Grid} from '@mui/material';
import SearchAccordion from 'fragments/SearchAccordion';
import FavouriteAccordion from 'fragments/FavouriteAccordion';
import MatchesList from 'fragments/MatchesList';
import LocationsByIndicatorAccordion from 'fragments/LocationsByIndicatorAccordion';
import SettingsButtons from './fragments/SettingsButtons';
import AroundMeButton from './fragments/AroundMeButton';
import Legend from '../../fragments/Legend';

const Startpage = () => (
  <>
    <Grid container spacing={[0, 1]}>
      <Grid item xs={12}>
        <SettingsButtons />
      </Grid>
      <Grid item xs={12}>
        <SearchAccordion />
      </Grid>
      <LocationsByIndicatorAccordion />
      <FavouriteAccordion />
      <MatchesList />
      <Grid item xs={12}>
        <AroundMeButton />
      </Grid>
    </Grid>
    <Legend />
  </>
);

export default Startpage;
