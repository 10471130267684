import {LatLngBoundsLiteral} from 'leaflet';
import {useSelector} from 'react-redux';
// import {useState} from 'react';
import {RootState} from '../store/store';

export const useHeatmapColorPicker = () => {
  const heatmapBoundingBox: LatLngBoundsLiteral | null = useSelector((state: RootState) => state.map.heatmapMetaData?.boundingBox || null);

  const getXImageCoordinate = (lat:number, lon:number):number | null => {
    if (!heatmapBoundingBox) {
      return null;
    }

    const lonMinimum = heatmapBoundingBox[0][1];
    const lonMaximum = heatmapBoundingBox[1][1];

    if (lon < lonMinimum || lon > lonMaximum) {
      return null;
    }

    const relativeLon = (lon - lonMinimum) / (lonMaximum - lonMinimum);

    // @ts-ignore
    return Math.floor(window.heatmapRenderingContext.canvas.width * relativeLon);
  };

  const getYImageCoordinate = (lat:number, lon:number):number | null => {
    if (!heatmapBoundingBox || !lat || !lon) {
      return null;
    }
    const latMinimum = heatmapBoundingBox[0][0];
    const latMaximum = heatmapBoundingBox[1][0];
    if (lat < latMinimum || lat > latMaximum) {
      return null;
    }
    const relativeLat = (lat - latMinimum) / (latMaximum - latMinimum);

    // @ts-ignore
    return Math.floor(window.heatmapRenderingContext.canvas.height * (1 - relativeLat));
  };

  const getColorAtCoordinate = (lat:number, lon:number) => {
    // @ts-ignore
    if (!window.heatmapRenderingContext || !heatmapBoundingBox || !lat || !lon) {
      return 'rgba(0,0,0,0)';
    }
    // @ts-ignore
    const point = window.heatmapRenderingContext.getImageData(getXImageCoordinate(lat, lon), getYImageCoordinate(lat, lon), 1, 1).data;
    // console.log('found color' + `rgba(${p[0]},${p[1]},${p[2]},${p[3]})`);
    return `rgba(${point[0]},${point[1]},${point[2]},${point[3]})`;
  };

  return {
    getColorAtCoordinate,
  };
};
