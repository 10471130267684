import React, {MouseEventHandler, useCallback, useState} from 'react';
import {Box, Button, IconButton} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import L, {
  LatLng,
  LatLngExpression, LeafletMouseEvent, LocationEvent, Map as LeafletMap,
} from 'leaflet';
import {useMap, useMapEvents} from 'react-leaflet';
import {MapPoint} from '../store/mapSlice';

interface ToolbarProps {
  mapPoint:MapPoint,
  alreadyInFavourites:boolean,
  onClose: MouseEventHandler,
  onRemoveFavourite: MouseEventHandler,
  onSaveFavourite: MouseEventHandler,
}

const Toolbar = ({
  mapPoint, alreadyInFavourites, onClose, onRemoveFavourite, onSaveFavourite,
}:ToolbarProps) => {
  const map:LeafletMap = useMap();
  const [currentZoom, setCurrentZoom] = useState<number>(map.getZoom());

  const zoomIn = () => {
    map.flyTo(new LatLng(mapPoint.lat, mapPoint.lon), 16);
  };

  const zoomOut = () => {
    map.flyTo(new LatLng(mapPoint.lat, mapPoint.lon), 14);
  };

  useMapEvents({
    zoomend: () => {
      setCurrentZoom(map.getZoom());
    },
  });

  const renderZoomButtons = useCallback(() => (currentZoom < 16 ? (
    <IconButton onClick={zoomIn} size="small">
      <ZoomInIcon />
    </IconButton>
  ) : (
    <IconButton onClick={zoomOut} size="small">
      <ZoomOutIcon />
    </IconButton>
  )), [currentZoom]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
      <IconButton onClick={onClose} size="medium">
        <ArrowBack />
      </IconButton>
      {alreadyInFavourites ? (
        <Button startIcon={(<FavoriteIcon />)} onClick={onRemoveFavourite}>
          Remove
        </Button>
      ) : (
        <Button startIcon={(<FavoriteOutlinedIcon />)} onClick={onSaveFavourite}>
          Save
        </Button>
      )}
      {renderZoomButtons()}
    </Box>
  );
};

export default Toolbar;
