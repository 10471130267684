import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import LocalBarIcon from '@mui/icons-material/LocalBar';
import FastFoodIcon from '@mui/icons-material/Fastfood';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import NightlifeIcon from '@mui/icons-material/PartyMode';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBasket';
import SportsBasketballIcon from '@mui/icons-material/SportsBasketball';
import HotelIcon from '@mui/icons-material/Hotel';
import GroupIcon from '@mui/icons-material/Group';
import InsightsIcon from '@mui/icons-material/Insights';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import TheatersIcon from '@mui/icons-material/Theaters';

import {FC} from 'react';
import {SatisfactionOption} from '../fragments/Map';

export const defaultSatisfactionOptions:SatisfactionOption[] = [
  {
    name: 'Supermärkte', id: 'SUPERMARKET', satisfactionDistance: 1000, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, minThreshold: 0,
  },
  {
    name: 'Restaurants', id: 'RESTAURANT', satisfactionDistance: 250, thresholdForMaxSatisfaction: 3, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Kneipen & Bars', id: 'PUB', satisfactionDistance: 1000.0, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Unterkünfte', id: 'HOTEL', satisfactionDistance: 250, thresholdForMaxSatisfaction: 3, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Fast food', id: 'FAST_FOOD', satisfactionDistance: 1000, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Cafes', id: 'CAFE', satisfactionDistance: 1000, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Nachtklubs', id: 'CLUB', satisfactionDistance: 1000, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Shopping', id: 'SHOPPING', satisfactionDistance: 1000, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Kulturangebote', id: 'CULTURE', satisfactionDistance: 250, thresholdForMaxSatisfaction: 3, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Bürgerliches', id: 'BOURGEOIS', satisfactionDistance: 1000, thresholdForMaxSatisfaction: 0, factorForNullSatisfaction: 1.5, isComputed: false,
  },
  {
    name: 'Sportangebote', id: 'SPORT',
  },
].map((satisfactionOption) => ({
  ...satisfactionOption,
  satisfactionDistance: satisfactionOption.satisfactionDistance || 1000,
  thresholdForMaxSatisfaction: satisfactionOption.thresholdForMaxSatisfaction || 3,
  factorForNullSatisfaction: satisfactionOption.factorForNullSatisfaction || 1.5,
  minThreshold: satisfactionOption.minThreshold || 0,
  computed: satisfactionOption.isComputed || false,
}));

export const getSatisfactionOptionIcon = (name:string):FC => {
  switch (name.toUpperCase()) {
    case 'GASTRONOMY':
      return RestaurantIcon;
    case 'SUPERMARKET':
      return LocalGroceryStoreIcon;
    case 'BARS':
      return LocalBarIcon;
    case 'FAST_FOOD':
      return FastFoodIcon;
    case 'CAFE':
      return LocalCafeIcon;
    case 'CLUB':
      return NightlifeIcon;
    case 'SHOP':
      return ShoppingBagIcon;
    case 'CULTURE':
      return TheatersIcon;
    case 'BOURGEOIS':
      return MonetizationOnIcon;
    case 'SPORT':
      return SportsBasketballIcon;
    case 'HOTEL':
      return HotelIcon;
    case 'CLASSIFICATION':
      return GroupIcon;
    case 'TOURISM':
      return InsightsIcon;
    default:
      return LocalGroceryStoreIcon;
  }
};

export const getSatisfactionLabel = (name:string):string => {
  switch (name.toUpperCase()) {
    case 'GASTRONOMY':
      return 'Restaurants';
    case 'BARS':
      return 'Bars';
    case 'CLASSIFICATION':
      return 'Classifikation';
    case 'SHOPPING_STREET':
      return 'Shopping street';
    case 'TOURISM':
      return 'Tourism';
    case 'SHOPS_BRANDS':
      return 'Shoppingketten';
    case 'GAMBLING':
      return 'Solala';
    case 'EXPENSIVE':
      return 'Expensive';
    default:
      return name.charAt(0).toUpperCase() + name.slice(1);
  }
};

export const getSatisfactionOption = (id:string):SatisfactionOption | undefined => defaultSatisfactionOptions.find((satisfactionOption) => satisfactionOption.id === id);

const addNullToEnd = (number: number) => {
  if (!number) {
    return '0.0';
  }
  return number;
};

// Analogy to backend logic, see 'getConfigHash'
export const generateSatisfactionLayerPath = (option:SatisfactionOption) => {
  if (option.computed) {
    return option.name.toLowerCase();
  }
  return `${option.name.toLowerCase()}_${option.thresholdForMaxSatisfaction}.0_${option.satisfactionDistance}.0_${addNullToEnd(option.factorForNullSatisfaction)}_${addNullToEnd(option.minThreshold)}`;
};
