import L, {
  ImageOverlay, LatLng, LatLngBounds, LatLngBoundsExpression, LatLngBoundsLiteral, Map as LeafletMap,
} from 'leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {
  setHeatmapMetaData,
  setHeatmapRequestBody,
  setHeatmapStatistics,
  setHighlights,
  setMatches,
} from 'store/mapSlice';
import {
  LoadingHeatmapState,
  setInvalidRequestedData,
  setLoadingHeatmapState,
  setMapFullscreen,
} from 'store/uiSlice';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {ReachabilityPoint, SatisfactionOption} from '../../fragments/Map';
import {RootState} from '../../store/store';
import {Match} from '../../store/uiSlice';
import {LocationIndicatorGroup} from '../../fragments/LocationsByIndicatorAccordion';
import {setProcessedChangedReachability} from '../../store/settingsSlice';

export interface HeatmapRequestBody {
  reachabilityPoints: ReachabilityPoint[],
  satisfactionOptions: SatisfactionOption[]
}

export interface HeatmapMetaDataResponse {
  boundingBox: LatLngBoundsLiteral,
  previewImageId: string,
  staticMapsExistAll: boolean,
  otherJobActive: boolean,
}

export interface HeatmapStatistics {
  locationIndicatorGroups: LocationIndicatorGroup[],

}

export interface DetectedArea {
  name:string,
  polygon:number[][][],
}
export interface Highlight {
  name:string,
  description:string,
  lat:number,
  lon:number,
  hightlightType:string,
}

export interface HeatmapResponse {
  statistics: HeatmapStatistics,
  imageId: string,
  matches: Match[],
  detectedAreas: DetectedArea[],
  highlights: Highlight[],
}

export const useHeatmap = (map: LeafletMap | undefined) => {
  const satisfactionOptions = useSelector((state: RootState) => state.settings.satisfactionOptions);
  const reachabilityPoints = useSelector((state: RootState) => state.settings.reachabilityPoints);
  const changedReachability = useSelector((state: RootState) => state.settings.changedReachability);
  const [lastHeatmapRequestBody, setLastHeatmapRequestBody] = useState<HeatmapRequestBody | null>(null);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  if (!map || reachabilityPoints.length === 0) {
    return null;
  }

  const buildAnalyzeRequest = (): HeatmapRequestBody => ({
    reachabilityPoints,
    satisfactionOptions: satisfactionOptions.filter((satisfactionOption) => satisfactionOption.thresholdForMaxSatisfaction > 0),
  });

  const buildRequestUrl = (path:string, heatmapRequestBody:HeatmapRequestBody) => `/api/${path}?settings=${encodeURIComponent(JSON.stringify(heatmapRequestBody))}`;

  const handleTooManyRequests = (response: Response) => {
    if (response.status === 429) {
      enqueueSnackbar('Too many requests. Please try again later.');
      dispatch(setLoadingHeatmapState(LoadingHeatmapState.Error));
      dispatch(setInvalidRequestedData(false));
      throw new Error('Too many requests');
    }
  };

  const requestHeatmapMetaData = async (heatmapRequestBody: HeatmapRequestBody): Promise<HeatmapMetaDataResponse | null> => {
    const requestUrl: string = buildRequestUrl('heatmap/meta-data', heatmapRequestBody);
    dispatch(setLoadingHeatmapState(LoadingHeatmapState.MetaDataRequesting));
    try {
      const response: Response = await fetch(requestUrl);
      handleTooManyRequests(response);
      if (response.ok) {
        return JSON.parse(await response.text());
      }
    } catch (exception) {
      console.error(exception);
    }
    enqueueSnackbar('Error creating your map');
    dispatch(setLoadingHeatmapState(LoadingHeatmapState.Error));
    dispatch(setInvalidRequestedData(true));
    return null;
  };

  const requestHeatmap = async (heatmapRequestBody: HeatmapRequestBody): Promise<HeatmapResponse | null> => {
    const requestUrl: string = buildRequestUrl('heatmap', heatmapRequestBody);
    dispatch(setLoadingHeatmapState(LoadingHeatmapState.HeatmapRequesting));
    try {
      const response: Response = await fetch(requestUrl);
      handleTooManyRequests(response);
      return JSON.parse(await response.text());
    } catch (exception) {
      console.error(exception);
    }
    enqueueSnackbar('Error creating your map');
    dispatch(setLoadingHeatmapState(LoadingHeatmapState.Error));
    dispatch(setInvalidRequestedData(true));
    return null;
  };

  const cleanMarkerLayers = () => {
    map.eachLayer((layer) => {
      // eslint-disable-next-line no-underscore-dangle,eqeqeq
      if (layer instanceof L.Marker) {
        // Todo.md: Was hiermit machen? Sonst löschen
        // map.removeLayer(layer);
      }
    });
  };

  const cleanImageOverlayLayers = (ignoreLayers: ImageOverlay[] | null) => {
    map.eachLayer((layer) => {
      // eslint-disable-next-line no-underscore-dangle,eqeqeq
      if (layer instanceof L.ImageOverlay && (ignoreLayers == null || ignoreLayers.find((i) => i != layer) == null)) {
        map.removeLayer(layer);
      }
    });
  };

  const setupPane = () => {
    if (!map.getPane('overlay')) {
      map.createPane('overlay');
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      map.getPane('overlay').style.zIndex = 50;
    }
  };

  const addGreyOutImageOverlay = (bounds: LatLngBoundsExpression) => {
    map.createPane('sss');

    const greyOut: ImageOverlay = L.imageOverlay('/grey.png', bounds, {opacity: 1, pane: 'sss'});
    greyOut.addTo(map);
    greyOut.bringToFront();
    return greyOut;
  };

  const createImageOverlay = (requestUrl: string, bounds: LatLngBounds): ImageOverlay[] => {
    const images = [];
    const imageOverlay: ImageOverlay = L.imageOverlay(requestUrl, bounds, {opacity: 0.83, pane: 'sss'});
    images.push(imageOverlay);
    // Create a bounding box that goes
    images.push(addGreyOutImageOverlay([[bounds.getNorth() - 4, bounds.getWest()], [bounds.getSouth(), bounds.getEast() + 4]]));
    images.push(addGreyOutImageOverlay([[bounds.getSouth() + 4, bounds.getWest()], [bounds.getNorth(), bounds.getEast() + 4]]));
    images.push(addGreyOutImageOverlay([[bounds.getSouth(), bounds.getEast()], [bounds.getNorth(), bounds.getEast() + 4]]));
    images.push(addGreyOutImageOverlay([[bounds.getSouth() + 4, bounds.getWest() - 4], [bounds.getNorth() - 4, bounds.getWest()]]));

    // const imageOverlay: ImageOverlay = L.imageOverlay(requestUrl, bounds, {opacity: 0.88, pane: 'overlay'});
    imageOverlay.addTo(map);
    imageOverlay.bringToFront();
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    map.getPane('overlay').style.zIndex = 50;

    return images;
  };

  const setWindowHeatmapRenderingContext = (imageId:string, imageLoadedCallback:Function) => {
    const canvas = document.createElement('canvas');

    const image = new Image();
    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      // @ts-ignore
      canvas.getContext('2d').drawImage(image, 0, 0);

      // @ts-ignore
      window.heatmapRenderingContext = canvas.getContext('2d');

      imageLoadedCallback();
    };
    image.onerror = () => {
      dispatch(setLoadingHeatmapState(LoadingHeatmapState.Error));
      dispatch(setInvalidRequestedData(false));
      enqueueSnackbar('Too many requests. Please try again later.');
    };
    image.crossOrigin = 'Anonymous';
    image.src = `/api/heatmap/image/${imageId}`;
  };

  const requestHeatmapImageOverlay = async (imageId: string, bounds: LatLngBounds) => {
    setupPane();

    const heatmapImageOverlays = await createImageOverlay(`/api/heatmap/image/${imageId}`, bounds);

    dispatch(setLoadingHeatmapState(LoadingHeatmapState.ImageRequesting));

    heatmapImageOverlays[0].on('load', async () => {
      cleanImageOverlayLayers(heatmapImageOverlays);
      setWindowHeatmapRenderingContext(imageId, () => dispatch(setLoadingHeatmapState(LoadingHeatmapState.Finished)));
    });

    heatmapImageOverlays[0].on('error', () => {
      dispatch(setLoadingHeatmapState(LoadingHeatmapState.Error));
      dispatch(setInvalidRequestedData(false));
      enqueueSnackbar('Fehler beim Erstellen deiner Karte');
    });
  };

  const requestHeatmapPreviewImageOverlay = (imageId: string, bounds: LatLngBounds) => {
    setupPane();
    createImageOverlay(`/api/heatmap/image/${imageId}`, bounds);
  };

  const resetLoading = () => {
    dispatch(setHeatmapRequestBody(null));
    dispatch(setLoadingHeatmapState(LoadingHeatmapState.NotStarted));
    dispatch(setMatches([]));
    cleanMarkerLayers();
    cleanImageOverlayLayers(null);
  };

  const processHeatmapMetaData = (heatmapMetaData: HeatmapMetaDataResponse) => {
    dispatch(setHeatmapMetaData(heatmapMetaData));

    map.setMinZoom(1);

    const minZoom = map.getBoundsZoom(new LatLngBounds(heatmapMetaData.boundingBox).pad(0.1));
    map.setMinZoom(minZoom);

    if (changedReachability) {
      map.fitBounds(new LatLngBounds(heatmapMetaData.boundingBox));
    }
    dispatch(setProcessedChangedReachability());
    map.setMaxBounds(new LatLngBounds(heatmapMetaData.boundingBox).pad(0.1));
  };

  const processHeatmapStatistics = (heatmapStatistics: HeatmapStatistics) => {
    dispatch(setHeatmapStatistics(heatmapStatistics));
  };

  const processHeatmapMatches = (matches:Match[]) => {
    dispatch(setMatches(matches));
  };

  const processHighlights = (highlights:Highlight[]) => {
    dispatch(setHighlights(highlights));
  };

  const getCenterOfReachabilityPoints = (): LatLng => {
    const latSum = reachabilityPoints.reduce((sum, point) => sum + point.lat, 0);
    const lonSum = reachabilityPoints.reduce((sum, point) => sum + point.lon, 0);
    return new LatLng(latSum / reachabilityPoints.length, lonSum / reachabilityPoints.length);
  };

  return ({
    loadHeatmap: async () => {
      dispatch(setMapFullscreen(false));

      const heatmapRequest: HeatmapRequestBody = buildAnalyzeRequest();
      if (lastHeatmapRequestBody != null && JSON.stringify(heatmapRequest) === JSON.stringify(lastHeatmapRequestBody)) {
        dispatch(setMapFullscreen(true));
        return;
      }

      resetLoading();
      const heatmapMetaData: HeatmapMetaDataResponse | null = await requestHeatmapMetaData(heatmapRequest);
      if (!heatmapMetaData) {
        dispatch(setMapFullscreen(true));
        return;
      }

      if (!heatmapMetaData.staticMapsExistAll) {
        map.flyTo(getCenterOfReachabilityPoints(), 12);
      }

      const latLngBounds = new LatLngBounds(heatmapMetaData.boundingBox);
      processHeatmapMetaData(heatmapMetaData);
      if (heatmapMetaData.previewImageId) {
        requestHeatmapPreviewImageOverlay(heatmapMetaData.previewImageId, latLngBounds);
      }

      const heatmapResponse: HeatmapResponse | null = await requestHeatmap(heatmapRequest);
      if (!heatmapResponse) {
        return;
      }

      setLastHeatmapRequestBody(heatmapRequest);
      processHeatmapStatistics(heatmapResponse.statistics);
      processHeatmapMatches(heatmapResponse.matches);
      processHighlights(heatmapResponse.highlights);

      // todo: visualize detected areas
      // L.polygon(heatmapResponse.detectedAreas.map((detectedArea) => detectedArea.polygon.map((coordinates) => coordinates.map((coordinate) => new LatLng(coordinate[0], coordinate[1]))))).addTo(map);
      dispatch(setMapFullscreen(true));
      await requestHeatmapImageOverlay(heatmapResponse.imageId, latLngBounds);
    },
  });
};
