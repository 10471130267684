import React, {MouseEventHandler, useMemo} from 'react';
import {
  Box, Button, IconButton, useMediaQuery,
} from '@mui/material';
import ArrowBack from '@mui/icons-material/ArrowBack';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import {LatLng, Map as LeafletMap} from 'leaflet';
import {useMap} from 'react-leaflet';
import Grid from '@mui/material/Grid';
import {useDispatch, useSelector} from 'react-redux';
import {useTheme} from '@mui/styles';
import Typography from '@mui/material/Typography';
import {MapPoint} from '../store/mapSlice';
import {RootState} from '../store/store';
import {SatisfactionOption} from './Map';
import {getSatisfactionLabel} from '../config/satisfactionOptions';
import {openSatisfactionDialog} from '../store/uiSlice';
import SatisfactionChips from './SatisfactionChips';

const LegendDataPerKind = [
  {
    satisfactionName: null,
    entries: [
      {color: 'rgb(255,226,94)', text: 'Weak'},
      {color: 'rgb(255,99,132)', text: 'Strong'},
    ],
  },
  {
    satisfactionName: 'CLASSIFICATION',
    entries: [
      {color: 'rgb(75,192,192)', text: 'Shops'},
      {color: 'rgb(255,99,132)', text: 'Gastronomy'},
      {color: 'rgb(255,205,86)', text: 'Bars'},
    ],
  },
];

const Legend = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const selectedSatisfactionOption:SatisfactionOption | null = useSelector((state: RootState) => state.settings.selectedSatisfactionOption);
  const satisfactionOptions:SatisfactionOption[] | null = useSelector((state: RootState) => state.settings.satisfactionOptions);
  const legendData = useMemo(() => LegendDataPerKind.find((lg) => lg.satisfactionName?.toUpperCase() === selectedSatisfactionOption?.name.toUpperCase()) || LegendDataPerKind[0], [LegendDataPerKind, selectedSatisfactionOption?.name]);
  const mapFullscreen = useSelector((state: RootState) => state.ui.mapFullscreen);

  if ((isMobile && !mapFullscreen) || !selectedSatisfactionOption) {
    return null;
  }

  const renderEntry = (color:string, text:string) => (
    <Box
      display="flex"
      alignItems="center"
      height="24px"
      marginY={isMobile ? '2px' : '5px'}
      key={text}
    >
      <Box
        width="12px"
        height="12px"
        marginY="auto"
        marginRight={1}
        sx={{
          background: color, borderRadius: '4px',
        }}
      />
      <Box
        flexGrow={1}
        height="100%"
        sx={{
          marginLeft: '5px',
        }}
      >
        {text}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: isMobile ? undefined : 10,
        top: isMobile ? '1vh' : undefined,
        left: isMobile ? '1.5' : undefined,
        zIndex: 99999,
        background: 'white',
        borderRadius: '10px',
        paddingY: '5px',
        paddingX: '15px',
        cursor: 'pointer',
      }}
      onClick={() => {
        dispatch(openSatisfactionDialog());
      }}
    >
      <Typography variant="caption">{getSatisfactionLabel(selectedSatisfactionOption.name)}</Typography>
      {legendData.entries.map((entry) => renderEntry(entry.color, entry.text))}
    </Box>
  );
};

export default Legend;
