import React, {
  SyntheticEvent, useCallback, useEffect, useRef, useState,
} from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import L, {
  LatLngExpression, LeafletMouseEvent, LocationEvent, Map as LeafletMap,
} from 'leaflet';
import {MapContainer, TileLayer, useMapEvents} from 'react-leaflet';
import {useDispatch, useSelector} from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
// import PixiOverlay from 'react-leaflet-pixi-overlay';
// import { renderToString } from 'react-dom/server';
import {useReachabilityMarker} from 'hooks/map/reachabilityMarker';
import {useHeatmap} from 'hooks/map/heatmap';
import {MapPoint, setViewBox} from 'store/mapSlice';
import {
  LoadingHeatmapState, openReachabilityDialog, openSatisfactionDialog,
  setLastClickedPlaceMenuItem,
  setMapFullscreen,
} from 'store/uiSlice';
import {Box, Button, IconButton} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/Menu';
import {debounce} from 'lodash';
import {
  generatePath, useLocation, useNavigate, useRoutes,
} from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import {useTheme} from '@mui/styles';
import ShadeMap from 'leaflet-shadow-simulator';
import osmtogeojson from 'osmtogeojson';
import NavigationIcon from '@mui/icons-material/Navigation';
import LayersIcon from '@mui/icons-material/Layers';
import {RootState} from '../store/store';
import Sidebar from './Sidebar';
import SatisfactionOptionsDialog from '../dialogues/SatisfactionOptionsDialog';
import ReachabilityPointDialog from '../dialogues/ReachabilityPointDialog';
import WelcomeDialog from '../dialogues/WelcomeDialog';
import {routes} from '../config/routeConfig';
import MapControlButtons from './MapControlButtons';
import {attributionMap} from '../config/attributionConfig';
import {generateSatisfactionLayerPath} from '../config/satisfactionOptions';
import {
  setDefaultSatisfactionOption,
  setSelectedSatisfactionOption,
} from '../store/settingsSlice';
import {useMyLocationMarker} from '../hooks/map/myLocationMarker';
import Legend from './Legend';
import {useHighlightsMarker} from '../hooks/map/highlightsMarker';
import ReplacementReachabilityDialog from '../dialogues/ReplacementReachabilityDialog';
import {ChipBackgroundSx} from '../components/ChipPanel';
import 'leaflet-doubletapdrag';
import 'leaflet-doubletapdragzoom';

export interface ReachabilityPoint {
  id:number,
  name:string
  lat:number,
  lon:number,
  travelType:string,
  maxTravelTime:number,
}

export interface SatisfactionOption {
  name:string,
  id:string,
  thresholdForMaxSatisfaction:number,
  satisfactionDistance:number,
  factorForNullSatisfaction:number,
  minThreshold:number,
  computed?:boolean,
  forClassification?:boolean,
  forAnalysis?:boolean,
}

export interface Place extends MapPoint{
  name:string,
  type:string
}

const useStyles = makeStyles(() => ({
  mapContainer: {
    width: '100%',
    height: '100%',
  },
}));

function Map() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));
  const anyDialogOpen = useSelector((state: RootState) => state.ui.openDialog !== null);
  const selectedSatisfactionOption:SatisfactionOption | null = useSelector((state: RootState) => state.settings.selectedSatisfactionOption);
  const satisfactionOptions:SatisfactionOption[] | null = useSelector((state: RootState) => state.settings.satisfactionOptions);
  const reachabilityPoints = useSelector((state: RootState) => state.settings.reachabilityPoints);
  const mapFullscreen = useSelector((state: RootState) => state.ui.mapFullscreen);
  const dispatch = useDispatch();
  const uiRef = useRef<HTMLDivElement>(null);
  const [mapRef, setMapRef] = useState<LeafletMap>();
  const position : LatLngExpression = [52.51, 13.309055];
  const zoom : number = 7;
  const classes = useStyles();
  const [satisfactionLayer, setSatisfactionLayer] = useState<any>(null);
  const imageOverlayHook = useHeatmap(mapRef);
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const loadingHeatmapState = useSelector((state: RootState) => state.ui.loadingHeatmapState);

  // Load kind types from backend and store them in redux
  useEffect(() => {
    const loadKindTypes = async () => {
      const requestUrl: string = '/api/kinds';
      const response: Response = await fetch(requestUrl);
      const kindTypes = await response.json();
      dispatch(setDefaultSatisfactionOption({satisfactionOptions: kindTypes}));
    };
    loadKindTypes();
  }, []);

  useEffect(() => {
    if (satisfactionOptions?.length > 0) {
      setLoading(false);
    }
  }, [satisfactionOptions]);

  useEffect(() => {
    if (!mapRef || anyDialogOpen || reachabilityPoints.length === 0 || !imageOverlayHook) {
      return;
    }

    (async () => {
      await imageOverlayHook.loadHeatmap();
    })();
  }, [mapRef !== null, anyDialogOpen, reachabilityPoints, imageOverlayHook !== null]);

  useEffect(() => {
    if (!mapRef || !satisfactionLayer) {
      return;
    }
    if (loadingHeatmapState !== LoadingHeatmapState.Finished) {
      mapRef.removeLayer(satisfactionLayer);
    }
  }, [loadingHeatmapState]);

  useEffect(() => {
    if (!mapRef || !selectedSatisfactionOption || anyDialogOpen || loadingHeatmapState !== LoadingHeatmapState.Finished) {
      return;
    }

    if (satisfactionLayer) {
      mapRef.removeLayer(satisfactionLayer);
    }
    const tileLayer = L.tileLayer(`/api/staticmap/${generateSatisfactionLayerPath(selectedSatisfactionOption)}/{z}/{x}/{y}.png`, {
      maxNativeZoom: 12,
      minNativeZoom: 6,
      minZoom: 6,
      attribution: '',
      opacity: 1,
    });

    tileLayer.on('tileload', () => {
      if (tileLayer) {
        tileLayer.getContainer()?.classList.add('static-map');
      }
      tileLayer.bringToBack();
    });

    setSatisfactionLayer(tileLayer);
    tileLayer.addTo(mapRef);
    tileLayer.bringToBack();

    // if (mapRef) {
    //   const shadeMap2 = new ShadeMap({
    //     date: new Date(), // display shadows for current date
    //     color: '#01112f', // shade color
    //     opacity: 0.7, // opacity of shade color
    //     apiKey: 'eyJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6ImJkM284cnVwM0Btb3ptYWlsLmNvbSIsImNyZWF0ZWQiOjE3MDU2ODEyMDU1MTQsImlhdCI6MTcwNTY4MTIwNX0.Cl32Xz0ELRbt6lMU7-RwormsbxTMXsHq_HuzoULz84Y', // obtain from https://shademap.app/about/
    //     terrainSource: {
    //       tileSize: 256, // DEM tile size
    //       maxZoom: 15, // Maximum zoom of DEM tile set
    //       getSourceUrl: ({ x, y, z }) => `https://s3.amazonaws.com/elevation-tiles-prod/terrarium/${z}/${x}/${y}.png`,
    //       getElevation: ({
    //         r, g, b, a,
    //       }) => (r * 256 + g + b / 256) - 32768,
    //     },
    //     getFeatures: async () => {
    //       try {
    //         if (mapRef.getZoom() > 13) {
    //           const bounds = mapRef.getBounds().pad(1.5);
    //           const north = bounds.getNorth();
    //           const south = bounds.getSouth();
    //           const east = bounds.getEast();
    //           const west = bounds.getWest();
    //           const query = `https://overpass-api.de/api/interpreter?data=%2F*%0AThis%20has%20been%20generated%20by%20the%20overpass-turbo%20wizard.%0AThe%20original%20search%20was%3A%0A%E2%80%9Cbuilding%E2%80%9D%0A*%2F%0A%5Bout%3Ajson%5D%5Btimeout%3A25%5D%3B%0A%2F%2F%20gather%20results%0A%28%0A%20%20%2F%2F%20query%20part%20for%3A%20%E2%80%9Cbuilding%E2%80%9D%0A%20%20way%5B%22building%22%5D%28${south}%2C${west}%2C${north}%2C${east}%29%3B%0A%29%3B%0A%2F%2F%20print%20results%0Aout%20body%3B%0A%3E%3B%0Aout%20skel%20qt%3B`;
    //           const response = await fetch(query);
    //           const json = await response.json();
    //           const geojson = osmtogeojson(json);
    //           // If no building height, default to one storey of 3 meters
    //           geojson.features.forEach((feature: any) => {
    //             if (!feature.properties) {
    //               feature.properties = {};
    //             }
    //             if (!feature.properties.height) {
    //               feature.properties.height = 3;
    //             }
    //           });
    //           return geojson.features;
    //         }
    //       } catch (e) {
    //         console.error(e);
    //       }
    //       return [];
    //     },
    //   }).addTo(mapRef);
    //   shadeMap2.setDate(new Date(Date.now() - 1000 * 60 * 60));
    // }
  }, [mapRef, anyDialogOpen, selectedSatisfactionOption, loadingHeatmapState]);

  useEffect(() => {
    if (uiRef.current === null) {
      return;
    }
    L.DomEvent.disableClickPropagation(uiRef.current);
    L.DomEvent.disableScrollPropagation(uiRef.current);
  }, [uiRef.current]);

  const dispatchViewBox = useCallback(
    debounce((boundingBoxString) => {
      dispatch(setViewBox(boundingBoxString));
    }, 2000),
    [],
  );

  const handleCreatedMap = (map:LeafletMap) => {
    setMapRef(map);

    map.on('moveend', () => {
      dispatchViewBox(map.getBounds().toBBoxString());
    });
  };

  const handleClickMap = (event:any) => {
    dispatch(setMapFullscreen(true));
    navigate(routes.Startpage.path);
    event.stopPropagation();
  };

  const handleClickShrinkMap = (syntheticEvent:SyntheticEvent) => {
    dispatch(setMapFullscreen(false));
    syntheticEvent.stopPropagation();
  };

  function adjustMapCenter(map: L.Map, overlayHeightVh: number) {
    // Calculate the pixel height of the overlay
    const overlayHeightPx = (overlayHeightVh / 100.0) * window.innerHeight;

    // Get the geographical bounds of the current map view
    const bounds = map.getBounds();

    // Find the geographical positions corresponding to the top and bottom of the map view
    const north = map.containerPointToLatLng([0, 0]);
    const south = map.containerPointToLatLng([0, window.innerHeight]);

    // Calculate the latitudinal range of the current view
    const latRange = north.lat - south.lat;

    // Calculate the latitudinal shift equivalent to the overlay height
    const latShift = (overlayHeightPx / window.innerHeight) * latRange;

    // Adjust the map center upwards by half of the latitudinal shift
    const newCenter = L.latLng(map.getCenter().lat + latShift / 2, map.getCenter().lng);

    // Set the map's view to the new center
    map.setView(newCenter, map.getZoom());
  }

  useEffect(() => {
    if (!mapRef) {
      return;
    }

    if (isMobile) {
      adjustMapCenter(mapRef, mapFullscreen ? 36 : -36);
    }
    setTimeout(() => {
      mapRef.invalidateSize();
    }, 400);
  }, [mapFullscreen]);

  useReachabilityMarker(mapRef);
  useHighlightsMarker(mapRef);
  // useMatchesMarker(mapRef);

  const MapEvents = () => {
    useMapEvents({
      click(event:LeafletMouseEvent) {
        // @ts-ignore
        if (mapRef.clicked === undefined) {
          // @ts-ignore
          mapRef.clicked = 0;
        }
        // @ts-ignore
        mapRef.clicked += 1;

        if (loadingHeatmapState !== LoadingHeatmapState.Finished && loadingHeatmapState !== LoadingHeatmapState.NotStarted) {
          return;
        }
        setTimeout(() => {
          // @ts-ignore
          if (mapRef.clicked === 1) {
            if (location.pathname === routes.Startpage.path) {
              dispatch(setLastClickedPlaceMenuItem(null));
              navigate(generatePath(routes.PlaceDetails.path, {lat: `${event.latlng.lat}`, lon: `${event.latlng.lng}`}));
              dispatch(setMapFullscreen(false));
            } else if (location.pathname !== routes.PlaceDetails.path) {
              navigate(routes.Startpage.path);
              dispatch(setMapFullscreen(true));
            }
          }
          // @ts-ignore
          mapRef.clicked = 0;
        }, isMobile ? 500 : 300);
      },
      dblclick(event:LeafletMouseEvent) {
        // @ts-ignore
        mapRef.clicked = 0;
        // @ts-ignore
        // mapRef.flyTo(event.latlng, mapRef.getZoom() + 1, {animate: true});
      },
      dragstart() {
        // @ts-ignore
        mapRef.clicked = 10;
      },
      dragend() {
        // @ts-ignore
        mapRef.clicked = 0;
      },
      drag() {
        // @ts-ignore
        mapRef.clicked = 0;
      },
      down() {
        // @ts-ignore
        mapRef.clicked = 0;
      },

    });

    useMyLocationMarker(mapRef);

    return <></>;
  };

  if (loading) {
    return (
      <div />
    );
  }

  return (
    <Box sx={{height: {xs: mapFullscreen ? '100vh' : '100vh', sm: '100vh'}}}>
      {!anyDialogOpen && (
      <>
        <Box
          onClick={handleClickMap}
          sx={{
            position: 'absolute',
            top: 0,
            backgroundColor: 'transparent',
            height: {xs: mapFullscreen ? '0' : '36vh', sm: '0'},
            width: '100%',
            zIndex: 10000,
          }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: '2vh',
            width: '100%',
            justifyContent: 'flex-start',
            display: {xs: mapFullscreen ? 'flex' : 'none', sm: 'none'},
            marginLeft: 1,
          }}
        >
          <Box>
            <IconButton sx={{backgroundColor: 'white', zIndex: 10000, border: '1px solid #cdcdcd'}} size="large" onClick={handleClickShrinkMap}>
              <ExpandLessIcon sx={{ zIndex: 10000}} htmlColor="#000" />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" width="80%">
            <Button
              startIcon={<NavigationIcon />}
              onClick={() => {
                dispatch(openReachabilityDialog());
              }}
              color="inherit"
              sx={{
                ...ChipBackgroundSx, marginRight: '22px !important', zIndex: 10000,
              }}
            >
              Location
            </Button>
            <Button
              startIcon={<LayersIcon />}
              onClick={() => {
                dispatch(openSatisfactionDialog());
              }}
              color="inherit"
              sx={{...ChipBackgroundSx, zIndex: 10000}}
            >
              Layer
            </Button>
          </Box>
        </Box>
      </>
      ) }
      <MapContainer
        className={classes.mapContainer}
        center={position}
        zoom={zoom}
        scrollWheelZoom
        zoomSnap={0.5}
        zoomControl={false}
        whenCreated={handleCreatedMap}
        onClick={handleClickMap}
        preferCanvas
        maxBoundsViscosity={0.8}
        minZoom={8}
      >
        <MapEvents />
        {/* <TileLayer */}
        {/*  attribution={isMobile ? '' : attributionMap} */}
        {/*    // url="https://api.mapbox.com/styles/v1/yourtravelroute/ckobtyp8h1dsf17oeahfoyxo3/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieW91cnRyYXZlbHJvdXRlIiwiYSI6ImNqenF6azliNjEyOGEzbHBjb3F0dDByY2YifQ.0QoMejqKvd38_2NVyOLW-g" */}
        {/*  url="/heatmap/static/2/{z}/{x}/{y}.png" */}
        {/*  minZoom={12} */}
        {/*  maxZoom={12} */}
        {/*  zIndex={1000} */}
        {/* /> */}
        <TileLayer
          attribution={isMobile ? '' : attributionMap}
          opacity={0.95}
          url="https://api.mapbox.com/styles/v1/yourtravelroute/clr3grt09017e01r54zyt03ue/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieW91cnRyYXZlbHJvdXRlIiwiYSI6ImNqenF6azliNjEyOGEzbHBjb3F0dDByY2YifQ.0QoMejqKvd38_2NVyOLW-g"
          // url="https://api.mapbox.com/styles/v1/yourtravelroute/ckobtyp8h1dsf17oeahfoyxo3/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoieW91cnRyYXZlbHJvdXRlIiwiYSI6ImNqenF6azliNjEyOGEzbHBjb3F0dDByY2YifQ.0QoMejqKvd38_2NVyOLW-g"
        />
        <div ref={uiRef}>
          {mapFullscreen && (<MapControlButtons />)}
          <Sidebar />
          <WelcomeDialog />
          <ReachabilityPointDialog />
          <SatisfactionOptionsDialog />
          <ReplacementReachabilityDialog />
        </div>
      </MapContainer>
    </Box>
  );
}

export default Map;
