export interface LocationIndicatorEntryArea {
  backendIdentifier: string, label:string
}

interface LocationIndicatorEntryAreas {
  BEST_AREA: LocationIndicatorEntryArea
}

export const locationIndicatorTypes:LocationIndicatorEntryAreas = {
  BEST_AREA: {backendIdentifier: 'MAX', label: 'Beste Lage'},
};
