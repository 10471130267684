import React, {
  SyntheticEvent, useCallback, useEffect, useState,
} from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip, Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Typography,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import List from '@mui/material/List';
import {Match, setDisplayMatchesNumber} from 'store/uiSlice';
import {debounce, get, orderBy} from 'lodash';
import {LatLng} from 'leaflet';
import {RootState} from 'store/store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {generatePath, useNavigate} from 'react-router-dom';
import {getLatLngBounds} from '../utils/mapUtils';
import PlaceMenuItem from './PlaceMenuItem';
import {routes} from '../config/routeConfig';
import MatchesMarkers from './MatchesMarker';

const DEFAULT_DISPLAYED_MATCHES = 3;
const MAX_DISPLAYED_MATCHES = 40;

const MatchesList = () => {
  const [filterVisibleEnabled, setFilterVisibleEnabled] = useState(false);
  const [visibleMatches, setVisibleMatches] = useState<Match[]>([]);
  const [expandedAccordion, setExpandedAccordion] = useState<boolean>(true);
  const matches:Match[] = useSelector((state: RootState) => orderBy(get(state, 'map.matches', []), ['score'], ['desc']));
  const boundingBoxString:string | null = useSelector((state: RootState) => get(state, 'map.viewBox', null));
  const displayMatchesNumber:number = useSelector((state: RootState) => get(state, 'ui.displayMatchesNumber') || DEFAULT_DISPLAYED_MATCHES);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateVisibleMatches = useCallback(
    debounce((currentBoundingBoxString) => {
      if (!currentBoundingBoxString) {
        setVisibleMatches([]);
        return;
      }

      const boundingBox = getLatLngBounds(currentBoundingBoxString);
      const currentlyVisibleMatches:Match[] = matches.filter((match) => match != null && boundingBox.contains(new LatLng(match.lat, match.lon)));
      if (visibleMatches.length === 0) {
        setVisibleMatches(currentlyVisibleMatches);
      }

      const matchesBeforeAndStillVisible:Match[] = visibleMatches.filter((visibleMatch) => currentlyVisibleMatches.some((currentlyVisibleMatch) => currentlyVisibleMatch === visibleMatch));
      const newVisible:Match[] = currentlyVisibleMatches.filter((currentlyVisibleMatch) => !visibleMatches.some((visibleMatch) => visibleMatch === currentlyVisibleMatch));
      const newVisibleMatchesTotal:Match[] = [...matchesBeforeAndStillVisible, ...newVisible];
      setVisibleMatches(newVisibleMatchesTotal);
    }, 1000),
    [],
  );

  useEffect(() => {
    updateVisibleMatches(boundingBoxString);
  }, [boundingBoxString]);

  useEffect(() => {
    if (visibleMatches.length === 0 && filterVisibleEnabled) {
      setFilterVisibleEnabled(false);
    }
  }, [visibleMatches.length, filterVisibleEnabled]);

  const activeMatchesList = filterVisibleEnabled ? visibleMatches : matches;

  const openMatchInDetail = (match:Match) => {
    navigate(generatePath(routes.MatchDetails.path, {id: match.id}));
  };

  const renderMatchesList = (maxEntries:number) => activeMatchesList.slice(0, maxEntries).map((match, index) => {
    const highlightMatch = !filterVisibleEnabled && !visibleMatches.some((visibleMatch) => visibleMatch === match);

    return (
      <PlaceMenuItem
        prefix={`${index + 1}. `}
        name={match.name}
        highlightName={highlightMatch}
        lat={match.lat}
        lon={match.lon}
        onDetail={() => openMatchInDetail(match)}
        key={match.name + match.lat + match.lon}
      />
    );
  });

  const handleFilterVisibleEnabled = (event:SyntheticEvent) => {
    event.stopPropagation();
    setFilterVisibleEnabled(!filterVisibleEnabled);
  };

  const renderVisibleMatchesFilter = () => (
    <Box display="flex" justifyContent="space-between" width="100%" alignItems="center">
      <Typography variant="body1">
        {'Hotels '}
      </Typography>
      {visibleMatches.length > 0
        ? (
          <Chip label={`Sichtbar: ${visibleMatches.length}`} variant={filterVisibleEnabled ? 'filled' : 'outlined'} onClick={handleFilterVisibleEnabled} />
        ) : (
          <Typography variant="body2">
            Keine im sichtbaren Bereich
          </Typography>
        )}
    </Box>
  );

  if (matches.length === 0) {
    return null;
  }

  const renderShowMoreButton = () => (
    <MenuItem onClick={() => dispatch(setDisplayMatchesNumber(Math.min(MAX_DISPLAYED_MATCHES, displayMatchesNumber + 10)))}>
      <ListItemIcon>
        <ExpandMoreIcon />
      </ListItemIcon>
      <ListItemText>
        Mehr anzeigen...
      </ListItemText>
    </MenuItem>
  );

  const renderShowLessButton = () => (
    <MenuItem onClick={() => dispatch(setDisplayMatchesNumber(Math.max(DEFAULT_DISPLAYED_MATCHES, displayMatchesNumber - 10)))}>
      <ListItemIcon>
        <ExpandLessIcon />
      </ListItemIcon>
      <ListItemText>
        Weniger anzeigen...
      </ListItemText>
    </MenuItem>
  );

  return (
    <Grid item xs={12}>
      {expandedAccordion && (<MatchesMarkers />)}
      <Accordion sx={{backgroundColor: 'rgba(255,255,255,0.92)'}} expanded={expandedAccordion} onChange={(event, isExpanded) => setExpandedAccordion(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box pr={4} sx={{width: '100%'}}>
            {renderVisibleMatchesFilter()}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav" sx={{width: '100%'}}>
            {renderMatchesList(displayMatchesNumber)}
            {displayMatchesNumber > DEFAULT_DISPLAYED_MATCHES && activeMatchesList?.length > DEFAULT_DISPLAYED_MATCHES
            && renderShowLessButton()}
            {displayMatchesNumber < MAX_DISPLAYED_MATCHES && activeMatchesList?.length > displayMatchesNumber
            && renderShowMoreButton()}
          </List>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default MatchesList;
