import React, {SyntheticEvent, useRef, useState} from 'react';
import {
  Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography,
} from '@mui/material';
import L, {LatLng, Map as LeafletMap} from 'leaflet';
import {useDispatch, useSelector} from 'react-redux';
import {Marker, useMap} from 'react-leaflet';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import FavoriteIcon from '@mui/icons-material/Favorite';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import {get} from 'lodash';
import {generatePath, useNavigate} from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import {addFavouritePlace, removeFavouritePlace} from '../store/settingsSlice';
import {RootState} from '../store/store';
import {shortBeautiful, shortStreet} from '../utils/stringUtils';
import {useHeatmapColorPicker} from '../hooks/heatmapColorPicker';
import {
  openReplacementReachabilityDialog,
  setLastClickedPlaceMenuItem,
} from '../store/uiSlice';
import {routes} from '../config/routeConfig';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../utils/markerUtils';
import {ReachabilityPoint} from './Map';
import {HeatmapMetaDataResponse} from '../hooks/map/heatmap';

interface PlaceMenuItemProps {
  name?: string,
  prefix?: string,
  suffix?: string,
  lat: number,
  lon: number,
  disableRatingBorder?: boolean,
  highlightName?: boolean,
  onDetail?: Function,
  onClickRemove?: Function
}

function PlaceMenuItem({
  name, prefix = '', suffix = '', lat, lon, disableRatingBorder = false, highlightName = false, onDetail, onClickRemove,
}: PlaceMenuItemProps) {
  const displayName = name || '(Name unbekannt)';

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const dispatch = useDispatch();
  const map: LeafletMap = useMap();
  const anchorReference: any = useRef(null);
  const lastClickedPlaceMenuItem = useSelector((state: RootState) => get(state, 'ui.lastClickedPlaceMenuItem', null));
  const alreadyInFavourites = useSelector((state: RootState) => get(state, 'settings.favouritePlaces', []))
    .some((favouritePlace: any) => favouritePlace.lat === lat && favouritePlace.lon === lon);
  const heatmapColorPicker = useHeatmapColorPicker();
  const navigate = useNavigate();
  const heatmapMetaDataResponse:HeatmapMetaDataResponse = useSelector((state: RootState) => get(state, 'map.heatmapMetaData', []));

  const isCurrentlyActive = lastClickedPlaceMenuItem && lastClickedPlaceMenuItem.name === name && lastClickedPlaceMenuItem.lat === lat && lastClickedPlaceMenuItem.lon === lon;

  const isOutsideOfBoundingBox = heatmapMetaDataResponse.boundingBox && !L.latLngBounds(heatmapMetaDataResponse.boundingBox).contains([lat, lon]);

  const clickSetSelectedPoint = (event:SyntheticEvent) => {
    if (!onDetail) {
      navigate(generatePath(`${routes.PlaceDetails.path}?name=${name}`, {lat: `${lat}`, lon: `${lon}`}));
    } else {
      onDetail();
    }
    event.stopPropagation();
  };

  const clickShowPointOnMap = (event:SyntheticEvent) => {
    if (isOutsideOfBoundingBox) {
      dispatch(openReplacementReachabilityDialog({
        name,
        lat,
        lon,
      } as ReachabilityPoint));
      return;
    }

    if (isCurrentlyActive) {
      clickSetSelectedPoint(event);
    } else {
      map.flyTo(new LatLng(lat, lon), 16, {animate: true});
      dispatch(setLastClickedPlaceMenuItem({
        name: name || '', lat, lon, type: '',
      }));
    }
  };

  const clickOpenGmaps = () => {
    window.open(`https://www.google.com/maps/place/${name}/@${lat.toString().replace(',', '.')},${lon.toString().replace(',', '.')},16z`);
  };

  const clickSaveFavourite = () => {
    dispatch(addFavouritePlace({lat, lon, name: displayName}));
  };

  const clickRemoveFavourite = () => {
    dispatch(removeFavouritePlace({lat, lon, name: displayName}));
  };

  const clickMenu = (event:SyntheticEvent) => {
    event.stopPropagation();
    setMenuVisible(!menuVisible);
  };

  const clickClose = (event: SyntheticEvent) => {
    event.stopPropagation();

    if (anchorReference.current && anchorReference.current.contains(event.target)) {
      return;
    }

    setMenuVisible(false);
  };

  const borderLeft = disableRatingBorder ? 'inherit' : `4px solid ${heatmapColorPicker.getColorAtCoordinate(lat, lon)}`;

  const nameTypographyProps = {
    fontStyle: isOutsideOfBoundingBox || highlightName ? 'italic' : 'inherit',
    fontWeight: isCurrentlyActive ? 'bold' : 'inherit',
  };

  const renderMarker = () => {
    const markerIcon = createMarkerIcon(MarkerColor.Orange, MarkerSize.Big);
    return (
      <Marker
        position={[lat, lon]}
        icon={markerIcon}
        eventHandlers={{
          click: (e) => {
            navigate(generatePath(`${routes.PlaceDetails.path}?name=${name}`, {lat: `${lat}`, lon: `${lon}`}));
          },
        }}
      />
    );
  };

  return (
    <>
      {isCurrentlyActive && renderMarker()}
      <MenuItem onClick={clickShowPointOnMap} sx={{whiteSpace: 'inherit', padding: '5px 0'}}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{width: '100%'}}>
          {(prefix + displayName).length > 35 ? (
            <Tooltip title={prefix + displayName} enterDelay={0}>
              <Box sx={{width: '100%', paddingLeft: '8px', borderLeft}}>
                <Typography variant="body1" fontStyle={nameTypographyProps.fontStyle} fontWeight={nameTypographyProps.fontWeight}>
                  {prefix + shortBeautiful(shortStreet(displayName), 30 - prefix.length - suffix.length) + suffix}
                </Typography>
              </Box>
            </Tooltip>
          ) : (
            <Box sx={{width: '100%', paddingLeft: '8px', borderLeft}}>
              <Typography variant="body1" fontStyle={nameTypographyProps.fontStyle} fontWeight={nameTypographyProps.fontWeight}>
                {shortBeautiful(prefix + shortStreet(displayName) + suffix, 30)}
              </Typography>
            </Box>
          )}
          <Box style={{whiteSpace: 'nowrap'}}>
            {isCurrentlyActive && (
              <IconButton onClick={clickSetSelectedPoint} size="small">
                <InfoIcon />
              </IconButton>
            )}
            <IconButton onClick={clickMenu} ref={anchorReference}>
              <MoreVertIcon />
            </IconButton>
            <Menu open={menuVisible} anchorEl={anchorReference.current} onClose={clickClose}>
              <MenuItem onClick={clickSetSelectedPoint}>
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText>
                  Details
                </ListItemText>
              </MenuItem>
              {onClickRemove && (
                <MenuItem onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClickRemove();
                }}
                >
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Löschen
                  </ListItemText>
                </MenuItem>
              )}
              <MenuItem onClick={clickOpenGmaps}>
                <ListItemIcon>
                  <OpenInNewIcon />
                </ListItemIcon>
                <ListItemText>
                  In GMaps öffnen
                </ListItemText>
              </MenuItem>
              {alreadyInFavourites ? (
                <MenuItem onClick={clickRemoveFavourite}>
                  <ListItemIcon>
                    <FavoriteIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Favorit entfernen
                  </ListItemText>
                </MenuItem>
              ) : (
                <MenuItem onClick={clickSaveFavourite}>
                  <ListItemIcon>
                    <FavoriteOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText>
                    Als Favorit speichern
                  </ListItemText>
                </MenuItem>
              )}
            </Menu>
          </Box>
        </Box>
      </MenuItem>
    </>
  );
}

export default PlaceMenuItem;
