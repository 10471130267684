import React, {useEffect, useState} from 'react';
import {Box, Typography} from '@mui/material';
import {Bar, PolarArea} from 'react-chartjs-2';
import {
  ArcElement, Legend, RadialLinearScale, Tooltip, Chart as ChartJS,
  CategoryScale, Chart, LinearScale, BarElement, Title,
} from 'chart.js';
import {colors} from 'config/colorConfig';
import {MapPoint} from '../store/mapSlice';
import {classificationTypes} from '../config/classificationTypes';
import {getSatisfactionLabel} from '../config/satisfactionOptions';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface ClassificationDiagramProps {
  mapPoint: MapPoint
}

interface ClassificationTypesScore {
  name: string,
  score: number
}

interface ClassificationResponse {
  classificationTypesScore: ClassificationTypesScore[]
}

// ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

const ClassificationDiagram = ({
  mapPoint,
}: ClassificationDiagramProps) => {
  const [classificationTypesScores, setClassificationTypesScores] = useState<ClassificationTypesScore[] | null>(null);

  const loadClassification = async () => {
    const requestUrl: string = `/api/classification?lat=${mapPoint.lat}&lon=${mapPoint.lon}`;
    const response: Response = await fetch(requestUrl);
    const classificationResponse: ClassificationResponse = JSON.parse(await response.text());
    setClassificationTypesScores(classificationResponse.classificationTypesScore.filter((classificationTypeScore) => classificationTypeScore.score > 0));
  };

  useEffect(() => {
    if (!mapPoint) {
      return;
    }
    setClassificationTypesScores(null);
    loadClassification();
  }, [mapPoint]);

  if (classificationTypesScores === null) {
    return (
      <Typography variant="h5" align="center">
        ...
      </Typography>
    );
  }
  if (classificationTypesScores.length === 0) {
    return (
      <Typography variant="caption" align="center">
        Location analysis without specification
      </Typography>
    );
  }

  const polarData = {
    labels: classificationTypesScores.map((classificationTypesScore) => getSatisfactionLabel(classificationTypesScore.name.toLowerCase())),
    datasets: [{
      label: 'Lageanalyse',
      data: classificationTypesScores.map((classificationTypesScore) => classificationTypesScore.score),
      backgroundColor: colors,
    }],
  };
  const barData = {
    labels: classificationTypesScores.map((classificationTypesScore) => getSatisfactionLabel(classificationTypesScore.name.toLowerCase())),
    datasets: [{
      label: 'Lageanalyse',
      data: classificationTypesScores.map((classificationTypesScore) => classificationTypesScore.score),
      backgroundColor: colors,
    }],
  };

  const options = {
    indexAxis: 'y' as const,
    elements: {
      bar: {
        borderWidth: 1,
      },
    },
    height: '300px',
    maintainAspectRatio: false,
    responsive: true,
    maxBarThickness: 30,
    barThickness: 15,
    borderRadius: 10,
    scales: {
      x: {
        max: 1,
        offset: false,
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          categoryPercentage: 0.0,
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: 'Location analysis',
      },
    },
  };
  return (
    <Box display="flex" justifyContent="center" mx={5}>
      <Box width="100%" height={45 + (classificationTypesScores.length * 30)} display="flex" justifyContent="center" alignItems="center">
        <Bar
          data={barData}
          options={options}
        />
      </Box>
    </Box>
  );
};

export default ClassificationDiagram;
