import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import DirectionsTransitIcon from '@mui/icons-material/DirectionsTransit';
import RadiusIcon from '@mui/icons-material/Radar';

export const getTravelTypeIcon = (travelType:string) => {
  switch (travelType) {
    case 'radius':
      return RadiusIcon;
    case 'bike':
      return DirectionsBikeIcon;
    case 'transit':
      return DirectionsTransitIcon;
    case 'car':
      return DriveEtaIcon;
    case 'walk':
      return DirectionsWalkIcon;
    default:
      return DriveEtaIcon;
  }
};

export const travelTypes = ['radius', 'bike', 'car', 'transit', 'walk'];
