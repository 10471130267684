import React, {useCallback} from 'react';
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import DeleteIcon from '@mui/icons-material/Delete';
import {get, sortBy} from 'lodash';
import PlaceIcon from '@mui/icons-material/Place';
import {useTheme} from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  closeDialog,
  Dialogs,
  openReachabilityDialog,
  setMapFullscreen,
} from '../store/uiSlice';
import {RootState} from '../store/store';
import {changeSatisfactionOption, setSelectedSatisfactionOption} from '../store/settingsSlice';
import {getSatisfactionLabel, getSatisfactionOptionIcon} from '../config/satisfactionOptions';
import {SatisfactionOption} from '../fragments/Map';
import {useCanCloseDialog} from '../hooks/canCloseDialog';
import SatisfactionChips from '../fragments/SatisfactionChips';

const useStyles = makeStyles(() => ({
  table: {
    minWidth: 650,
  },
  formControl: {
    margin: 1,
    minWidth: 120,
  },
}));

function SatisfactionOptionsDialog() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogOpen = useSelector((state: RootState) => get(state, 'ui.openDialog') === Dialogs.Satisfaction);
  const satisfactionOptions:SatisfactionOption[] = useSelector((state: RootState) => sortBy(state.settings.satisfactionOptions, 'name'));
  const selectedSatisfactionOption:SatisfactionOption | null = useSelector((state: RootState) => state.settings.selectedSatisfactionOption);
  const [showDetails, setShowDetails] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const canClose:boolean = useCanCloseDialog();

  const handleClose = () => {
    if (!canClose) {
      return;
    }
    dispatch(closeDialog());
    dispatch(setMapFullscreen(true));
  };

  const handleBack = () => {
    dispatch(closeDialog());
    dispatch(openReachabilityDialog());
  };

  const handleChangeShowDetails = (event:any) => {
    setShowDetails(event.target.checked);
  };

  const onChange = (id:string, thresholdForMaxSatisfaction:number, satisfactionDistance:number) => {
    dispatch(changeSatisfactionOption({id, thresholdForMaxSatisfaction, satisfactionDistance}));
  };

  const onSelectionChange = (satisfactionOption:SatisfactionOption | null) => {
    dispatch(setSelectedSatisfactionOption({satisfactionOption}));
  };

  const renderIcon = (name:string) => {
    const SatisfactionOptionIcon = getSatisfactionOptionIcon(name);
    return <SatisfactionOptionIcon />;
  };

  const renderDetailOptions = useCallback(() => {
    const activatedSatOptions = satisfactionOptions.filter((satisfactionOption) => satisfactionOption.thresholdForMaxSatisfaction > 0);

    return (
      <Grid container spacing={2}>
        {showDetails && activatedSatOptions.length > 0 && (
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {activatedSatOptions.filter((satisfactionOption) => satisfactionOption.forAnalysis).map((satisfactionOption: SatisfactionOption) => (
                  <TableRow key={satisfactionOption.id}>
                    <TableCell component="th" scope="row">
                      {renderIcon(satisfactionOption.name)}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant={satisfactionOption.thresholdForMaxSatisfaction > 0 ? 'body1' : 'body2'}
                      >
                        {satisfactionOption.name}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <FormControl className={classes.formControl}>
                        <InputLabel id={`labelMaxThreshold${satisfactionOption.name}`}>Anzahl</InputLabel>
                        <Select
                          labelId={`labelMaxThreshold${satisfactionOption.name}`}
                          label="Anzahl"
                          value={satisfactionOption.thresholdForMaxSatisfaction}
                          onChange={(e: any) => onChange(satisfactionOption.id, e.target.value, satisfactionOption.satisfactionDistance)}
                          size="small"
                        >
                          <MenuItem value="0">Egal</MenuItem>
                          <MenuItem value="1">1</MenuItem>
                          <MenuItem value="2">2</MenuItem>
                          <MenuItem value="3">3</MenuItem>
                          <MenuItem value="4">4</MenuItem>
                          <MenuItem value="5">5</MenuItem>
                          <MenuItem value="7">7</MenuItem>
                          <MenuItem value="10">10</MenuItem>
                          <MenuItem value="15">15</MenuItem>
                          <MenuItem value="20">20</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell align="right">
                      <FormControl className={classes.formControl}>
                        <InputLabel id={`labelMaxThreshold${satisfactionOption.name}`}>Innerhalb</InputLabel>
                        <Select
                          labelId={`labelMaxThreshold${satisfactionOption.name}`}
                          label="Innerhalb"
                          value={satisfactionOption.satisfactionDistance}
                          onChange={(e: SelectChangeEvent<number>) => onChange(satisfactionOption.id, satisfactionOption.thresholdForMaxSatisfaction, e.target.value as number)}
                          size="small"
                        >
                          <MenuItem value={50}>50m</MenuItem>
                          <MenuItem value={75}>75m</MenuItem>
                          <MenuItem value={100}>100m</MenuItem>
                          <MenuItem value={250}>250m</MenuItem>
                          <MenuItem value={500}>500m</MenuItem>
                          <MenuItem value={750}>750m</MenuItem>
                          <MenuItem value={1000}>1km</MenuItem>
                          <MenuItem value={1500}>1.5km</MenuItem>
                          <MenuItem value={2000}>2km</MenuItem>
                          <MenuItem value={3000}>3km</MenuItem>
                          <MenuItem value={5000}>5km</MenuItem>
                          <MenuItem value={10000}>10km</MenuItem>
                          <MenuItem value={20000}>20km</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        )}
        {/* <Grid item xs={12}> */}
        {/*  <FormControlLabel */}
        {/*    control={( */}
        {/*      <Switch */}
        {/*        checked={showDetails} */}
        {/*        onChange={handleChangeShowDetails} */}
        {/*      /> */}
        {/* )} */}
        {/*    label="Detaillierte Einstellungen anzeigen" */}
        {/*  /> */}
        {/* </Grid> */}
      </Grid>
    );
  },
  [satisfactionOptions]);

  const renderChips = useCallback(() => (
    <SatisfactionChips satisfactionOptions={satisfactionOptions} selectedSatisfactionOption={selectedSatisfactionOption} />
  ),
  [satisfactionOptions]);

  return (
    <Dialog
      open={dialogOpen}
      onClose={handleClose}
      fullWidth
      maxWidth="md"
      scroll="body"
      BackdropProps={{sx: {backgroundColor: 'rgba(255, 255, 255, 0.96)'}}}
      PaperProps={{
        elevation: 0,
        sx: fullScreen ? {maxWidth: {xs: '100% !important', sm: 'inherit'}, width: {xs: '100% !important', sm: 'inherit'}, margin: 0} : null,
      }}
      // fullScreen={fullScreen}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <PlaceIcon sx={{fontSize: '1.6rem', marginRight: 2}} />
            <Typography variant="h5">
              Environmental analysis
            </Typography>
          </Box>
          {canClose && (
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Which category should be displayed?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {renderChips()}
          </Grid>
          <Grid item xs={12}>
            {renderDetailOptions()}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button onClick={handleBack} color="primary" variant="outlined">
            Back
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained" disabled={!selectedSatisfactionOption}>
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

export default SatisfactionOptionsDialog;
