import L, {Map as LeafletMap, Marker} from 'leaflet';
import {useDispatch, useSelector} from 'react-redux';
import React, {useEffect} from 'react';
import {openReachabilityDialog} from '../../store/uiSlice';
import {RootState} from '../../store/store';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../../utils/markerUtils';
import {Highlight} from './heatmap';

function createMarkerDiv(highlight: Highlight) {
  const content = L.DomUtil.create('div', 'map-popup');
  content.innerHTML = `<b>${highlight.name}</b>
    <br />
    ${highlight.description}
    <br />
    <a href='https://www.google.com/maps/search/${highlight.name}/@${highlight.lat},${highlight.lon},12z' target='_blank' > > Details</a>`;
  return content;
}

// <!--    // Erreichbarkeitskriterium: ${reachabilityPoint.travelType} within ${Math.ceil(reachabilityPoint.maxTravelTime / 60)}min -->

const createMarker = (map:LeafletMap, dispatch:Function, reachabilityPoint: Highlight) => {
  const content = createMarkerDiv(reachabilityPoint);
  // L.DomEvent.addListener(content, 'click', () => {
  //   dispatch(openReachabilityDialog());
  //   map.closePopup();
  // });

  const icon = createMarkerIcon(MarkerColor.Grey, MarkerSize.Small);

  const marker = L.marker([reachabilityPoint.lat, reachabilityPoint.lon], { icon }).bindPopup(content);
  marker.addTo(map);
  return marker;
};

export const useHighlightsMarker = (map:LeafletMap | undefined) => {
  const highlights = useSelector((state: RootState) => state.map.highlights);
  const dispatch = useDispatch();
  const [markers, setMarkers] = React.useState<Marker[]>([]);

  useEffect(() => {
    if (map === undefined || !highlights) {
      return;
    }
    markers.forEach((marker) => {
      map.removeLayer(marker);
    });

    const newMarkers = highlights.map((reachabilityPoint) => createMarker(map, dispatch, reachabilityPoint));
    setMarkers(newMarkers);
  }, [map, highlights]);
};
