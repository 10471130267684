import React, {useEffect, useState} from 'react';
import {Typography} from '@mui/material';
import {MapPoint} from '../store/mapSlice';

interface AddressLabelProps {
  mapPoint:MapPoint,
  variant: 'big' | 'medium',
  onLoadedAddress?: (address:string)=> void
}

const AddressLabel = ({
  mapPoint, variant, onLoadedAddress,
}:AddressLabelProps) => {
  const [address, setAddress] = useState<string>('');

  const loadAddress = async () => {
    const requestUrl:string = `/api/places/address?lat=${mapPoint.lat}&lon=${mapPoint.lon}`;
    const response:Response = await fetch(requestUrl);
    const requestedAddress:string = await response.text();
    setAddress(requestedAddress.replace(' null', ''));
    if (onLoadedAddress) {
      onLoadedAddress(requestedAddress);
    }
  };

  useEffect(() => {
    if (!mapPoint) {
      return;
    }
    setAddress('');
    loadAddress();
  }, [mapPoint]);

  if (!address) {
    return (
      <Typography variant="h5" align="center">
        ...
      </Typography>
    );
  }

  if (variant === 'medium') {
    // Todo: Fix address format from backend to json
    return (
      <>
        <Typography variant="h6" align="center">
          {address?.split(' (')[0]}
          {` (${address?.split(' (')[1]}`}
        </Typography>
      </>
    );
  }

  return (
    <>
      <Typography variant="h5" align="center">
        {address?.split(' (')[0]}
      </Typography>
      <Typography variant="subtitle2" align="center">
        {`(${address?.split(' (')[1]}`}
      </Typography>
    </>
  );
};

export default AddressLabel;
