import L, {Map as LeafletMap, Marker} from 'leaflet';
import React, {useEffect} from 'react';
import {createMarkerIcon, MarkerColor, MarkerSize} from '../../utils/markerUtils';

export interface MarkerData {
  lat:number,
  lon:number,
  popupHtml:string
}

function createMarkerDiv(popupHtml:string) {
  const content = L.DomUtil.create('div', 'map-popup');
  content.innerHTML = popupHtml;
  return content;
}

const createMarker = (map:LeafletMap, markerData:MarkerData, markerColor:MarkerColor) => {
  const icon = createMarkerIcon(markerColor, MarkerSize.Medium);

  const marker = L.marker([markerData.lat, markerData.lon], { icon });
  if (markerData.popupHtml) {
    const content = createMarkerDiv(markerData.popupHtml);
    L.DomEvent.addListener(content, 'click', () => {
      map.closePopup();
    });
    marker.bindPopup(content);
  }

  marker.addTo(map);
  return marker;
};

export const usePlacesMarker = (map:LeafletMap | undefined, markerData:MarkerData[] | undefined, markerColor:MarkerColor) => {
  const [markers, setMarkers] = React.useState<Marker[]>([]);

  useEffect(() => {
    if (map === undefined) {
      return;
    }

    markers.forEach((marker) => {
      map.removeLayer(marker);
    });

    if (!markerData) {
      return;
    }

    const newMarkers = markerData.map((marker) => createMarker(map, marker, markerColor));
    setMarkers(newMarkers);
  }, [map, markerData]);
};
